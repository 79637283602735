// MUI imports
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SchoolIcon from '@mui/icons-material/School';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentsIcon from '@mui/icons-material/Payments';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import TheatersIcon from '@mui/icons-material/Theaters';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import RedeemIcon from '@mui/icons-material/Redeem';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import QuizIcon from '@mui/icons-material/Quiz';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import EditNoteIcon from '@mui/icons-material/EditNote';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

// My component imports
import { deleteCardMovement, deleteCardPayment, deleteMovement, deleteTransfer } from './api';
import { apiDateSerializator } from './date';
import themes from '../styles/themes';


function getCategoryIcon(category, fontSize) {
  let iconToReturn = <></>
  switch (category) {
    case 'Sueldo':
      iconToReturn = <PaidIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Compras':
      iconToReturn = <ShoppingBasketIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Supermercado':
      iconToReturn = <ShoppingCartIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Gastos fijos':
      iconToReturn = <ReceiptIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Varios':
      iconToReturn = <AutoAwesomeMotionIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Salud':
      iconToReturn = <LocalHospitalIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Educación':
      iconToReturn = <SchoolIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Deportes':
      iconToReturn = <SportsSoccerIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Transporte':
      iconToReturn = <DirectionsCarIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Ocio':
      iconToReturn = <TheatersIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Restaurante':
      iconToReturn = <RestaurantIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Deporte':
      iconToReturn = <SportsGymnasticsIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Delivery':
      iconToReturn = <DeliveryDiningIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Regalos':
      iconToReturn = <RedeemIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Suscripciones':
      iconToReturn = <SubscriptionsIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Vacaciones':
      iconToReturn = <BeachAccessIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Desconocido':
      iconToReturn = <QuizIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Renta':
      iconToReturn = <PriceCheckIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Utilidades':
      iconToReturn = <PriceCheckIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Intereses':
      iconToReturn = <PriceCheckIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Dividendos':
      iconToReturn = <PriceCheckIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Pago de tarjeta':
      iconToReturn = <CreditCardOffIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    case 'Transferencia':
      iconToReturn = <RequestQuoteIcon fontSize={fontSize ? fontSize : "large"} />
      break;
    default:
      iconToReturn = <PaymentsIcon fontSize={fontSize ? fontSize : "large"} />
  }
  return <div sx={{ marginBottom: "5px" }}>{iconToReturn}</div>;
}

function getCustodyAgentTypeIcon(type, fontSize, color) {
  let iconToReturn = <></>
  switch (type) {
    case 'Banco':
      iconToReturn = <AccountBalanceIcon
        fontSize={fontSize ? fontSize : "large"}
        sx={{ color: color ? color : null }}
      />
      break;
    case 'Corredor de bolsa':
      iconToReturn = <ShowChartIcon
        fontSize={fontSize ? fontSize : "large"}
        sx={{ color: color ? color : null }}
      />
      break;
    case 'Lugar físico':
      iconToReturn = <LocalAtmIcon
        fontSize={fontSize ? fontSize : "large"}
        sx={{ color: color ? color : null }}
      />
      break;
    case 'Deudas':
      iconToReturn = <EditNoteIcon
        fontSize={fontSize ? fontSize : "large"}
        sx={{ color: color ? color : null }}
      />
      break;
    default:
      iconToReturn = <AccountBalanceIcon
        fontSize={fontSize ? fontSize : "large"}
        sx={{ color: color ? color : null }}
      />
  }
  return iconToReturn;
}

function getObjectType(obj) {
  if (obj) {
    if (obj.cuenta !== undefined && obj.categoria !== undefined) {
      return {
        type: "Movimiento",
        variant: "Movimiento"
      };
    }
    if (obj.estado_de_cuenta !== undefined && obj.categoria !== undefined) {
      return {
        type: "MovimientoTarjeta",
        variant: "MovimientoTarjeta",
      };
    }
    if (obj.cuenta_origen !== undefined && obj.cuenta_destino !== undefined) {
      let objectToReturn = {
        type: "Transferencia",
      }
      if (obj.cuenta_origen.moneda.id === obj.cuenta_destino.moneda.id) {
        objectToReturn.variant = "Transferencia";
      } else {
        objectToReturn.variant = "CambioDeMoneda";
      }
      return objectToReturn;
    }
    if (obj.cuenta !== undefined && obj.monto_origen !== undefined) {
      return {
        type: "PagoDeTarjeta",
        variant: "PagoDeTarjeta",
      }
    }
  }

  return {
    type: "Undefined",
    variant: "Undefined"
  }
}

function purgeQueryObject(myObject) {
  let objectToReturn = [];
  // console.log(myObject);
  for (let key in myObject) {
    // console.log(key);
    const condition1 = Array.isArray(myObject[key]) && myObject[key].length > 0;
    const condition2 = typeof myObject[key] === 'string' && myObject[key] !== '';
    const condition3 = typeof myObject[key] === 'boolean';
    const condition4 = typeof myObject[key] === 'number';
    const condition5 = myObject[key] instanceof Date;
    const condition6 = myObject[key].id !== undefined && typeof myObject[key].id === 'number';
    if (condition1) {
      objectToReturn = objectToReturn.concat(myObject[key].map(item => [key, item.id]));
    }
    if (condition2 || condition3 || condition4) {
      objectToReturn = objectToReturn.concat([[key, myObject[key]]]);
    }
    if (condition5) {
      objectToReturn = objectToReturn.concat([[key, apiDateSerializator(myObject[key])]]);
    }
    if (condition6) {
      objectToReturn = objectToReturn.concat([[key, myObject[key].id]]);
    }
  }
  return objectToReturn;
}

function filterLabels(movements, qObj) {
  // API returns all movements with at least one label, I must filter the movements that dosen't have all labes at the same time
  return movements.filter((mov, index) => {
    if (qObj.labels.filter((label, ind) => mov.etiquetas.map(item => item.id).includes(label.id)).length < qObj.labels.length) {
      return false;
    } else {
      return true;
    }
  });
}

function accountsOkForTransfers(accounts) {
  return accounts.filter(item => {
    if (accounts.filter(acct => acct.moneda.id === item.moneda.id).length > 1) {
      return true
    } else {
      return false
    }
  }).length > 0
}

function accountsOkForCurrencyExchanges(accounts) {
  return accounts.filter(item => {
    if (accounts.filter(acct => acct.moneda.id !== item.moneda.id).length > 0) {
      return true
    } else {
      return false
    }
  }).length > 0
}

function getButtonText(intent, isExpense) {
  let intentText = '';
  let expenseText = '';
  if (intent === 'create') {
    intentText = 'Registar ';
  } else {
    intentText = 'Modificar ';
  }
  if (isExpense) {
    expenseText = 'compra'
  } else {
    expenseText = 'ingreso'
  }
  return intentText + expenseText
}

function generateDeleteFunction(mov) {
  if (mov.tarjeta !== undefined && mov.monto !== undefined) {
    // This is unequivocally a MovimientoTarjeta
    return deleteCardMovement;
  } else if (mov.cuenta !== undefined && mov.monto !== undefined) {
    // This is unequivocally a Movimiento
    return deleteMovement;
  } else if (mov.cuenta_origen !== undefined) {
    // This is unequivocally a Transferencia
    return deleteTransfer;
  } else if (mov.cuenta !== undefined && mov.monto_destino !== undefined) {
    // This is unequivocally a PagoDeTarjeta
    return deleteCardPayment;
  }
}

function isExpense(mov) {
  const movType = getObjectType(mov);
  if (movType.type === "MovimientoTarjeta") {
    // This is unequivocally a MovimientoTarjeta
    return mov.monto < 0;
  } else if (movType.type === "Movimiento") {
    // This is unequivocally a Movimiento
    return mov.monto < 0;
  } else if (movType.type === "Transferencia") {
    // This is unequivocally a Transferencia
    return mov.monto_origen < 0;
  } else if (movType.type === "PagoDeTarjeta") {
    return true;
  }
}

function sortedMovementList(movementList, sortBy, ascending, account) {
  // Function that recieves a list of Movimiento, MovimientoTarjeta, and Transferencia and return a sorted list according to a criteria
  let newMovementList = JSON.parse(JSON.stringify(movementList));
  if (sortBy === "date") {
    newMovementList = newMovementList.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
  } else if (sortBy === "ammount") {
    newMovementList = newMovementList.sort((a, b) => {
      let new_a = JSON.parse(JSON.stringify(a));
      let new_b = JSON.parse(JSON.stringify(b));
      if (new_a.cuenta_destino !== undefined) {
        // a is a transfer
        if (account === undefined) {
          // If function is called without an account I sort by monto_origen
          new_a.monto = new_a.monto_origen;
        } else {
          // If function is called with an account I sort based on the proper value, monto_origen or monto_destino
          if (new_a.cuenta_origen.id === account.id) {
            new_a.monto = new_a.monto_origen;
          } else {
            new_a.monto = new_a.monto_destino;
          }
        }
      };
      if (new_b.cuenta_destino !== undefined) {
        // b is a transfer
        if (account === undefined) {
          // If function is called without an account I sort by monto_origen
          new_b.monto = new_b.monto_origen;
        } else {
          // If function is called with an account I sort based on the proper value, monto_origen or monto_destino
          if (new_b.cuenta_origen.id === account.id) {
            new_b.monto = new_b.monto_origen;
          } else {
            new_b.monto = new_b.monto_destino;
          }
        }
      }
      if (new_a.cuenta !== undefined && new_a.monto_destino !== undefined) {
        // a is a cardPayment
        new_a.monto = new_a.monto_origen;
      };
      if (new_b.cuenta !== undefined && new_b.monto_destino !== undefined) {
        // b is a cardPayment
        new_b.monto = new_b.monto_origen;
      };
      if (new_a.tarjeta !== undefined && new_a.cantidad_de_cuotas !== undefined) {
        // a is a cardMovement
        new_a.monto = new_a.monto * new_a.cantidad_de_cuotas
      };
      if (new_b.tarjeta !== undefined && new_b.cantidad_de_cuotas !== undefined) {
        // a is a cardMovement
        new_b.monto = new_b.monto * new_b.cantidad_de_cuotas
      };
      return new_a.monto - new_b.monto
    });
  };
  if (!ascending) {
    newMovementList = newMovementList.reverse();
  };
  return newMovementList;
}

function groupByCategory(movementList) {
  return movementList.reduce((group, movement) => {
    const { categoria } = movement;
    group[categoria.nombre] = group[categoria.nombre] ?? [];
    group[categoria.nombre].push(movement);
    return group;
  }, {});
}

function groupCardMovementsByCurrency(movementList) {
  return movementList.reduce((group, movement) => {
    const { moneda } = movement;
    group[moneda.nombre_corto] = group[moneda.nombre_corto] ?? [];
    group[moneda.nombre_corto].push(movement);
    return group;
  }, {});
}

function getAccountMovementsSum(movementList, account) {
  const transfers = movementList.filter(mov => getObjectType(mov).type === "Transferencia");
  const movements = movementList.filter(mov => getObjectType(mov).type === "Movimiento");
  const payments = movementList.filter(mov => getObjectType(mov).type === "PagoDeTarjeta");
  const transfers_sum = parseFloat(transfers.reduce((accumulator, value) => {
    if (value.cuenta_origen.id === account.id) {
      return accumulator + value.monto_origen;
    } else if (value.cuenta_destino.id === account.id) {
      return accumulator + value.monto_destino;
    } else {
      return accumulator;
    }
  }, 0))
  const movements_sum = parseFloat(movements.reduce((accumulator, value) => accumulator + value.monto, 0))
  const payments_sum = parseFloat(payments.reduce((accumulator, value) => accumulator + value.monto_origen, 0))

  return transfers_sum + movements_sum + payments_sum;
}

function getAccountMovementAvg(movementList, account) {
  const sum = getAccountMovementsSum(movementList, account);
  return sum === 0 ? 0 : sum / movementList.length;
}

function getAccountMovmentMax(movementList, account) {
  const transfers = movementList.filter(mov => getObjectType(mov).type === "Transferencia");
  const movements = movementList.filter(mov => getObjectType(mov).type === "Movimiento");
  const payments = movementList.filter(mov => getObjectType(mov).type === "PagoDeTarjeta")
  const movementsMax = movements.length > 0 ?
    movements.reduce((accumulator, value) => accumulator.monto > value.monto ? accumulator : value) : undefined;
  const transfersMax = transfers.length > 0 ?
    transfers.reduce((accumulator, value) => {
      const accumValueToCompare = accumulator.cuenta_origen.id === account.id ? accumulator.monto_origen : accumulator.monto_destino;
      const valueValueToCompare = value.cuenta_origen.id === account.id ? value.monto_origen : value.monto_destino;
      return accumValueToCompare > valueValueToCompare ? accumulator : value;
    }) :
    undefined;
  const paymentsMax = payments.length > 0 ?
    payments.reduce((accumulator, value) => accumulator.monto_origen > value.monto_origen ? accumulator : value) :
    undefined;
  let myArray = [];
  if (movementsMax) {
    myArray = myArray.concat(parseFloat(movementsMax.monto));
  };
  if (transfersMax) {
    myArray = myArray.concat(parseFloat(transfersMax.cuenta_origen.id === account.id ? transfersMax.monto_origen : transfersMax.monto_destino));
  };
  if (paymentsMax) {
    myArray = myArray.concat(parseFloat(paymentsMax.monto_origen));
  };
  if (myArray.length === 0) {
    return "N/A";
  } else {
    return parseFloat(Math.max(...myArray));
  }
}

function getAccountMovmentMin(movementList, account) {
  const transfers = movementList.filter(mov => getObjectType(mov).type === "Transferencia");
  const movements = movementList.filter(mov => getObjectType(mov).type === "Movimiento");
  const payments = movementList.filter(mov => getObjectType(mov).type === "PagoDeTarjeta")
  const movementsMin = movements.length > 0 ?
    movements.reduce((accumulator, value) => accumulator.monto < value.monto ? accumulator : value) :
    undefined;
  const transfersMin = transfers.length > 0 ? transfers.reduce((accumulator, value) => {
    const accumValueToCompare = accumulator.cuenta_origen.id === account.id ? accumulator.monto_origen : accumulator.monto_destino;
    const valueValueToCompare = value.cuenta_origen.id === account.id ? value.monto_origen : value.monto_destino;
    return accumValueToCompare < valueValueToCompare ? accumulator : value;
  }) : undefined;
  const paymentsMin = payments.length > 0 ?
    payments.reduce((accumulator, value) => accumulator.monto_origen < value.monto_origen ? accumulator : value) :
    undefined;
  let myArray = [];
  if (movementsMin) {
    myArray = myArray.concat(parseFloat(movementsMin.monto));
  };
  if (transfersMin) {
    myArray = myArray.concat(parseFloat(transfersMin.cuenta_origen.id === account.id ? transfersMin.monto_origen : transfersMin.monto_destino));
  };
  if (paymentsMin) {
    myArray = myArray.concat(parseFloat(paymentsMin.monto_origen));
  };
  if (myArray.length === 0) {
    return "N/A";
  } else {
    return parseFloat(Math.min(...myArray));
  }
}

function getAmmountText(ammount, currency) {
  const ammountText = ammount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  const currencyText = currency ? (currency.nombre_corto + ' ') : '';
  return currencyText + ammountText;
}

function getAmountDisplayColor(number) {
  return number >= 0 ? themes.primary.palette.primary.success : themes.primary.palette.primary.error;
}

function parseName(name) {
  return name.length > 10 ? name.slice(0, 9) + "..." : name;
}

function deepObjectCopy(myObject) {
  let newObject = JSON.parse(JSON.stringify(myObject));
  for (let key in myObject) {
    if (myObject[key] instanceof Date) {
      newObject[key] = new Date(newObject[key]);
    };
  }
  return newObject;
}

function convertCurrencyValue(value, fromCurrency, toCurrency, exchangeRates) {
  // Function that converts a value from one currency to another. The exchange rates are always based upon the UYU
  // currency. The exchange rates are stored in the exchangeRates list, which contains dictionaries with the following structure:
  // {
  // "compra": value,
  // "venta": value,
  // "moneda": Moneda object,
  // "fecha": Date object
  // }
  // There are neither exchange rates for the UYU currency, as it is the base currency, nor for the UYT currency, as it is always worth 1 UYU,
  // so the function will return the value if the fromCurrency is UYU and the toCurrency is UYT or viceversa.
  // The condition to identify UYU or UYT is moneda.nombre_corto === "UYU" || moneda.nombre_corto === "UYT"

  if ((fromCurrency.nombre_corto === "UYU" && toCurrency.nombre_corto === "UYT") || (fromCurrency.nombre_corto === "UYT" && toCurrency.nombre_corto === "UYU")) {
    return value;
  }

  let fromRate = (fromCurrency.nombre_corto === "UYU" || fromCurrency.nombre_corto === "UYT") ? { venta: 1 } : exchangeRates.find(rate => rate.moneda.id === fromCurrency.id);
  let toRate = (toCurrency.nombre_corto === "UYU" || toCurrency.nombre_corto === "UYT") ? { compra: 1 } : exchangeRates.find(rate => rate.moneda.id === toCurrency.id);

  if (fromCurrency.nombre_corto !== "UYU" && fromCurrency.nombre_corto !== "UYT" && !fromRate) {
    throw new Error("From Currency not supported. From Currency: " + fromCurrency.nombre_corto + " To Currency: " + toCurrency.nombre_corto + "fromRate: " + fromRate + " toRate: " + toRate);
  }
  if (toCurrency.nombre_corto !== "UYU" && toCurrency.nombre_corto !== "UYT" && !toRate) {
    throw new Error("To Currency not supported.");
  }

  let valueInUYU = value;
  if (fromCurrency.nombre_corto !== "UYU" && fromCurrency.nombre_corto !== "UYT") {
    valueInUYU = value * fromRate.venta;
  }

  let convertedValue = valueInUYU;
  if (toCurrency.nombre_corto !== "UYU" && toCurrency.nombre_corto !== "UYT") {
    convertedValue = valueInUYU / toRate.compra;
  }

  return convertedValue;
}

function parseJwt(token) {
  const Buffer = require('buffer').Buffer;
  return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}



export {
  deepObjectCopy,
  getCategoryIcon,
  getCustodyAgentTypeIcon,
  filterLabels,
  accountsOkForTransfers,
  accountsOkForCurrencyExchanges,
  getButtonText,
  generateDeleteFunction,
  isExpense,
  sortedMovementList,
  purgeQueryObject,
  groupByCategory,
  groupCardMovementsByCurrency,
  getAccountMovementsSum,
  getAccountMovementAvg,
  getAccountMovmentMax,
  getAccountMovmentMin,
  getAmmountText,
  getAmountDisplayColor,
  getObjectType,
  parseName,
  convertCurrencyValue,
  parseJwt
};