// React imports
import { useState, useEffect } from "react";

// Proptypes imports
import PropTypes from "prop-types";

// MUI imports
import { Autocomplete, Avatar, Box, Stack, TextField, Typography } from "@mui/material";

// My components imports
import { promotionalCodePropType, userPropType } from "../../utils/myPropTypes.js";

function CodigoPicker({ codigos, onChange, initialCode }) {

  // State constants
  const [selectedCode, setSelectedCode] = useState(initialCode);

  // Effect hooks
  useEffect(() => {
    if (selectedCode) {
      onChange(selectedCode);
    }
  }, [selectedCode]);

  // Handlers
  const handleChange = (event, newValue) => {
    setSelectedCode(newValue);
  };

  return codigos &&
    <Autocomplete
      value={selectedCode}
      onChange={handleChange}
      options={codigos}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.codigo}
      renderOption={(props, option) => {
        return (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={{ fontSize: 14 }}>{option.codigo}</Typography>
            </Stack>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Elige un código"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />;

}

CodigoPicker.propTypes = {
  codigos: PropTypes.arrayOf(userPropType).isRequired,
  onChange: PropTypes.func.isRequired, // Function to be called when the user changes
  initialCode: promotionalCodePropType.isRequired,
}

export default CodigoPicker;
