// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Stack } from "@mui/material";

// My components imports
import MyPaper from "../basic/myPaper.js";
import MyMenuComponent from "./myMenuComponent.js";
import { getProducts } from "../../utils/adminApi.js";
import MyLoadingList from "../basic/myLoadingList.js";
import { urls } from "../../settings.js";
import ProductList from "../lists/productList.js";
import CreateModifyProduct from "../app/createModifyProduct.js";
import AddButton from "../basic/addButton.js";

function ProductsPage() {

  // State constants
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  // Auxiliary functions
  function updateProductList(isMounted) {
    setLoading(true);
    getProducts()
      .then(response => {
        if (isMounted) {
          console.log(response);
          setLoading(false);
          setProducts(response.results);
          setShowForm(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      updateProductList(isMounted);
    }
    return () => { isMounted = false };
  }, []);

  return <MyMenuComponent
    links={[
      { url: urls.plansAndSubscriptionsUrl, name: 'Planes y suscripciones' },
    ]}
    currentPageName={'Productos'}
  >
    <MyPaper>
      {showForm ? <CreateModifyProduct onCancel={() => setShowForm(false)} onSubmit={updateProductList} /> :

        loading ? <MyLoadingList /> :
          <Stack>
            <ProductList products={products} />
            <AddButton onClick={() => setShowForm(true)} />
          </Stack>
      }
    </MyPaper>
  </MyMenuComponent>;
}

export default ProductsPage;