export const SUBSCRIPTION_PROVIDERS = ['PayPal', 'DLocal', 'Local'];

export const ACTIVE_SUBSCRIPTION_PROVIDERS = ['Local'];

export const EMPTY_PRODUCT = {
  id: null,
  name: '',
  provider: 'Local',
  description: '',
};

export const emptyPayPalPlan = {
  id: '',
  id_de_producto: '',
  nombre: '',
  descripcion: '',
  status: 'ACTIVE',
  frecuencia: 'mes',
  intervalo: 1,
  monto: 0,
  moneda: 'USD',
}

export const emptyPromotionalCode = {
  codigo: '',
  usuario_id: '',
}