// MUI imports
import MyPaper from "./myPaper";
import { Stack } from "@mui/material";

// PropTypes imports
import PropTypes from 'prop-types';

// My components imports
import AddCircleIcon from '@mui/icons-material/AddCircle';

function AddButton(props) {

  // Constants
  const buttonColor = props.disabled ? 'disabled' : 'primary';

  // Handlers
  const handleClick = () => {
    if (props.disabled) return;
    props.onClick();
  }

  return <MyPaper shallow>
    <Stack direction="row" justifyContent="flex-end">
      <AddCircleIcon sx={{ fontSize: "65px" }} color={buttonColor} onClick={handleClick} />
    </Stack>
  </MyPaper>
}

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired, // Function to be called when user clicks de button
  disabled: PropTypes.bool, // If true, the button will be disabled, default is false
}

export default AddButton;