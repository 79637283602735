// React imports
import React from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { IconButton, Stack, Typography } from '@mui/material';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

// My component imports
import { paypalPlanPropType } from '../../utils/myPropTypes';

function PayPalPlanListItem(props) {

  // Destructure props
  const { plan, onModify } = props;

  // Other constants
  const statusColor = plan.status === 'ACTIVE' ? 'primary' : 'grey';
  const statusTitleColor = plan.status === 'ACTIVE' ? 'primary' : '#b1b1b3';
  const statusTextColor = plan.status === 'ACTIVE' ? '#19191a' : '#b1b1b3';

  // Handlers
  const handleToggleClick = e => {
    e.stopPropagation();
    if (plan.status === 'ACTIVE') {
      props.onDeactivate(plan);
    } else {
      props.onActivate(plan);
    }
  }

  return <Stack direction="row" onClick={() => onModify(plan)}>
    <Stack>
      <Typography variant="h6" color={statusTitleColor}>{`${plan.nombre} ${plan.codigo_promocional ? '(' + plan.codigo_promocional.codigo + ')' : ''}`}</Typography>
      <Typography variant="caption" color={statusTextColor}>{plan.descripcion}</Typography>
      <Typography variant="subtitle1" color={statusTextColor}>{`Se paga cada ${plan.intervalo} ${plan.frecuencia}`}</Typography>
      <Typography variant="subtitle1" color={statusTextColor}>{`Precio: ${plan.moneda} ${plan.monto}`}</Typography>
      <Typography variant="caption" color={statusTextColor}>{`ID de producto: ${plan.id_de_producto}`}</Typography>
    </Stack>
    <Stack flexGrow={1} justifyContent="center" alignItems="flex-end">
      <IconButton edge="end" aria-label="delete" onClick={handleToggleClick}>
        {plan.status === 'ACTIVE' ? <ToggleOnIcon fontSize='large' color={statusColor} /> : <ToggleOffIcon fontSize='large' color={statusColor} />}
      </IconButton>
    </Stack>
  </Stack >
}

PayPalPlanListItem.propTypes = {
  plan: paypalPlanPropType.isRequired,
  onModify: PropTypes.func.isRequired,
  onDeactivate: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
};

export default PayPalPlanListItem;