// Styles imports
import "../../styles/login.css";

// MUI imports
import { Box, useTheme, useMediaQuery } from "@mui/material";

// My components imports
import GoogleLoginComponent from "./googleLogin.js";

function LoginComponent(props) {
  const logoUrl = '/FinanzasUY500croped.png';
  const imageUrl = '/fondo.jpg'; // Cambio aquí
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const logoWidth = isMobile ? '350px' : '600px';

  return <Box style={{
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    backgroundImage: `url(${imageUrl})`, // Establece la imagen como fondo
    backgroundSize: 'cover', // Asegura que la imagen cubra todo el fondo
    backgroundPosition: 'center' // Centra la imagen en el fondo
  }}>
    <Box
      sx={{
        position: 'absolute',
        top: '12%',
        left: '50%',
        transform: 'translate(-50%, 0)',
        textAlign: 'center',
        zIndex: 1,
      }}
    >
      <img src={logoUrl} alt='Logo' style={{ width: logoWidth }} />
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '400px' }}>
        <GoogleLoginComponent />
      </div>
    </Box>
  </Box>
}

export default LoginComponent;
