// React imports
import { useState } from "react"

// PropTypes imports
import PropTypes from "prop-types"

// MUI imports
import { Button, Stack, MenuItem, Select, TextField, useTheme } from "@mui/material"

// My components imports
import { productPropType } from "../../utils/myPropTypes"
import { ACTIVE_SUBSCRIPTION_PROVIDERS } from "../../utils/constants"


export default function ProductForm({ product, onSubmit, onCancel }) {

  // Other constants
  const theme = useTheme()

  // State constants
  const [name, setName] = useState(product.name)
  const [provider, setProvider] = useState(product.provider ? product.provider : ACTIVE_SUBSCRIPTION_PROVIDERS[0])
  const [description, setDescription] = useState(product.description)

  // Handlers
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log("submitting product from form");
    onSubmit({ 'nombre': name, 'proveedor': provider, 'descripcion': description })
  }

  const handleCancel = (e) => {
    e.preventDefault()
    onCancel();
  }
  return (
    <Stack spacing={1}>
      <TextField
        label="Nombre"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Select
        value={provider}
        onChange={(e) => setProvider(e.target.value)}
        fullWidth
        margin="normal"
      >
        {ACTIVE_SUBSCRIPTION_PROVIDERS.map((provider) => (<MenuItem key={provider} value={provider}>{provider}</MenuItem>))}
      </Select>
      <TextField
        label="Descripción"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleSubmit}>Guardar</Button>
      <Button variant="contained" color="cancel" onClick={handleCancel}>Cancelar</Button>
    </Stack>
  )
}

ProductForm.propTypes = {
  product: productPropType,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}