// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack } from "@mui/material";

// My component imports
import { paypalProductPropType } from '../../utils/myPropTypes';
import PayPalProductListItem from '../basic/payPalProductListItem';


function ProductList(props) {

  // Destructure props
  const { products, onSelect, onDelete } = props;

  return <Stack direction="column" spacing={2} sx={{ padding: '10px' }}>
    {products.map(
      (product) => {
        return <PayPalProductListItem
          key={product.id}
          product={product}
          onModify={() => console.log('Modify method not implemented')}
          onDelete={() => console.log('Delete method not implemented')}
        />
      }
    )}
  </Stack>

}

ProductList.propTypes = {
  products: PropTypes.arrayOf(paypalProductPropType).isRequired,
};

export default ProductList;