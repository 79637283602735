// React Router imports
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// MUI imports
import { ThemeProvider } from '@mui/material/styles';

// My components imports
import './App.css';
import Home from "./components/home.js";
import WithAuth from "./utils/auth.js";
import PlansAndSubscriptionsPage from './components/app/plansAndSubscriptionsPage.js';
import { urls } from './settings.js';
import PlansPage from './components/app/plansPage.js';
import ProductsPage from './components/app/productsPage.js';
import SyncPayPalWithDbPage from './components/app/syncPayPalWithDbPage.js';
import PromotionalCodesPage from './components/app/promotionalCodesPage.js';
import themes from "./styles/themes.js";

function App() {
  return (
    <ThemeProvider theme={themes['primary']}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={urls.plansAndSubscriptionsUrl} element={<PlansAndSubscriptionsPage />} />
          <Route path={urls.plansUrl} element={<PlansPage />} />
          <Route path={urls.productsUrl} element={<ProductsPage />} />
          <Route path={urls.synchronizePayPalUrl} element={<SyncPayPalWithDbPage />} />
          <Route path={urls.promotionalCodesUrl} element={<PromotionalCodesPage />} />
        </Routes>
      </Router>
    </ThemeProvider>

  );
}

export default WithAuth(App);
