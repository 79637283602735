// React imports
import { useState, useEffect } from "react";

// React Router imports
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// AWS imports
import { Amplify, Hub } from 'aws-amplify';

// My components imports
import LoginComponent from "../components/auth/login.js";
import awsConfiguration, { urls } from "../settings.js";
import ErrorPage from "../components/app/errorPage.js";
import { isLoggedIn, logoutUser, logInUser } from "./authUtils.js";
import { appName } from "../settings.js";


// AWS configuration
Amplify.configure(awsConfiguration);

// Auth wrapper
function WithAuth(WrappedComponent) {

  return function ExtendedComponent(props) {

    // State variables
    const [signedIn, setSignedIn] = useState(false);
    // const navigate = useNavigate();

    // Effect hooks
    useEffect(() => {
      let isMounted = true;
      // constants
      if (isMounted) {
        try {
          if (isLoggedIn()) {
            setSignedIn(true);
          } else {
            setSignedIn(false);
          }
        } catch (err) {
          logoutUser(); // This should clear app tokens and generate a signOut event in the Hub, and that in turn should call setSignedIn(false)
        }
      }

      const listener = async (data) => {
        switch (data.payload.event) {
          case 'identityProviderSignIn':
            await logInUser(data.payload.data.token); // Sign in the user in the app and trigger a signIn event in the Hub
            break;
          case 'identityProviderSignOut':
            // User has signed out with the identity provider, let's log the user out of the app
            logoutUser(); // This should clear app tokens and generate a signOut event in the Hub, and that in turn should call setSignedIn(false)
            break;
          case 'signIn':
            // User has signed in to the app, let's set signedIn to true          
            setSignedIn(true);
            break;
          case 'signOut':
            // User has signed out of the app, let's set signedIn to false
            setSignedIn(false);
            break;
          case 'signIn_failure':
            // User failed to sign in to the app, let's set signedIn to false and redirect to the login error page
            setSignedIn(false);
            // navigate(urls.loginError);
            break;
          case 'tokenRefresh_failure':
            // Token refresh failed, let's log the user out
            logoutUser(); // This should clear app tokens and generate a signOut event in the Hub, and that in turn should call setSignedIn(false)
            break;
          default:
            break;
        }
      };

      const stopListening = Hub.listen(appName, listener);

      return () => {
        isMounted = false;
        stopListening();
      };
    }, []);

    return signedIn ? <WrappedComponent /> :
      <Router>
        <Routes>
          <Route path={urls.loginError} element={<ErrorPage />} />
          <Route path="/" element={<LoginComponent />} />
        </Routes>
      </Router>

  }

}

export default WithAuth;