// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack } from "@mui/material";

// My component imports
import PayPalPlanListItem from "../basic/paypalPlanListItem";
import { paypalPlanPropType } from '../../utils/myPropTypes';

// Auxiliary functions
function ordenarPlanes(planes) {
  return planes.sort((a, b) => {
    if (a.status === "ACTIVE" && b.status !== "ACTIVE") {
      return -1;
    }
    if (a.status !== "ACTIVE" && b.status === "ACTIVE") {
      return 1;
    }
    return 0;
  });
}

function PayPalPlansList(props) {

  // Destructure props
  const { plans, onModify, onActivate, onDeactivate } = props;

  return <Stack direction="column" spacing={2} sx={{ padding: '10px' }}>
    {ordenarPlanes(plans).map(plan => {
      return <PayPalPlanListItem key={plan.id} plan={plan} onModify={() => onModify(plan)} onActivate={() => onActivate(plan)} onDeactivate={() => onDeactivate(plan)} />
    })}
  </Stack>

}

PayPalPlansList.propTypes = {
  plans: PropTypes.arrayOf(paypalPlanPropType).isRequired,
  onModify: PropTypes.func,
  onActivate: PropTypes.func,
  onDeactivate: PropTypes.func,
};

export default PayPalPlansList;