// React imports
import { useState, useEffect } from 'react'

// PropTypes imports
import PropTypes from 'prop-types'

// MUI component imports
import { Select, MenuItem } from "@mui/material"

// My component imports
import { paypalProductPropType } from '../../utils/myPropTypes'


function ProductSelectInput(props) {

  // Destructure props
  const { products, initialProduct, onSelect } = props;

  // State constants
  const [product, setProduct] = useState(initialProduct ? initialProduct : products[0])

  // Effect hooks
  useEffect(() => {
    if (products.length > 0 && !initialProduct) {
      setProduct(products[0])
    }
  }, [products])

  useEffect(() => {
    onSelect(product);
  }, [product])

  // Event handlers
  const handleChange = (event) => {
    setProduct(event.target.value)
  }

  return <Select
    labelId="product-select-label"
    id="product-select"
    value={product}
    label="Product"
    onChange={handleChange}
    disabled={props.disabled ? props.disabled : false}
  >
    {products.map(product => <MenuItem key={product.id} value={product}>{`${product.nombre}`}</MenuItem>)}
  </Select>
}

ProductSelectInput.propTypes = {
  initialProduct: paypalProductPropType,
  products: PropTypes.arrayOf(paypalProductPropType).isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool, // Optional, defaults to false
};

export { ProductSelectInput };