// React router imports
import { useNavigate } from "react-router-dom";

// MUI imports
import { Stack } from "@mui/material";
import { List, ListItemAvatar, Avatar, ListItemText } from "@mui/material";
import PaidIcon from '@mui/icons-material/Paid';
import CategoryIcon from '@mui/icons-material/Category';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// My components imports
import MyPaper from "../basic/myPaper.js";
import MyMenuComponent from "./myMenuComponent.js";
import MyListItem from "../basic/myListItem.js";
import { urls } from "../../settings.js";

function PlansAndSubscriptionsPage() {

  const navigate = useNavigate();

  return <MyMenuComponent
    links={[
      // { url: urls.homeUrl, name: 'Inicio' },
    ]}
    currentPageName={'Planes y suscripciones'}
  >
    <Stack direction="column" justifyContent="center" alignItems="center">
      <MyPaper>
        <Stack spacing={1}>
          <List >
            <MyListItem onClick={event => navigate(urls.productsUrl)}>
              <ListItemAvatar>
                <Avatar>
                  <CategoryIcon color="primary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Productos" secondary="Productos de PayPal" />
            </MyListItem>
            <MyListItem onClick={event => navigate(urls.plansUrl)}>
              <ListItemAvatar>
                <Avatar>
                  <PaidIcon color="primary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Planes" secondary="Planes de PayPal" />
            </MyListItem>
            <MyListItem onClick={event => navigate(urls.promotionalCodesUrl)}>
              <ListItemAvatar>
                <Avatar>
                  <LocalOfferIcon color="primary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Códigos" secondary="Gestionar códigos promocionales" />
            </MyListItem>
            <MyListItem onClick={event => navigate(urls.synchronizePayPalUrl)}>
              <ListItemAvatar>
                <Avatar>
                  <CloudSyncIcon color="primary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Sincronizar" secondary="Sincronizar PayPal con la base de datos" />
            </MyListItem>
          </List>
        </Stack>
      </MyPaper>
    </Stack>
  </MyMenuComponent>;
}

export default PlansAndSubscriptionsPage;