// PropTypes imports
import PropTypes from 'prop-types';

// PropTypes for label object
export const labelPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the label
  texto: PropTypes.string.isRequired, // This is the text of the label
  sub: PropTypes.string.isRequired, // This represents the username of the owner of the label
});

// PropTypes for category object
export const categoryPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the category
  nombre: PropTypes.string.isRequired, // This is the name of the category
  codigo: PropTypes.string.isRequired, // Thœis is the code of the category
});

// PropTypes for currency object
export const currencyPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the currency
  nombre: PropTypes.string.isRequired, // This is the name of the currency
  nombre_corto: PropTypes.string.isRequired, // This is the short name of the currency
});

// PropTypes for Country object
export const countryPropType = PropTypes.shape({
  nombre: PropTypes.string.isRequired, // This is the name of the country
  codigo: PropTypes.string.isRequired, // This is the code of the country
  moneda_oficial: PropTypes.shape(currencyPropType), // This is the official currency of the country
});

// PropTypes for a simplified version of the family object
export const simplifiedFamilyPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the family
  nombre: PropTypes.string.isRequired, // This is the name of the family
});

// PropTypes for family invitations
export const familyInvitationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the family invitation
  correo: PropTypes.string.isRequired, // This is the email of the family invitation
  familia: PropTypes.shape(simplifiedFamilyPropType), // This is the family of the family invitation
  revocada: PropTypes.bool, // This is the status of the family invitation
  revocada_en: PropTypes.string, // This is the date when the family invitation was revoked, format: YYYY-MM-DD
  aceptada: PropTypes.bool, // This is the status of the family invitation
  aceptada_en: PropTypes.string, // This is the date when the family invitation was accepted, format: YYYY-MM-DD
  rechazada: PropTypes.bool, // This is the status of the family invitation
  rechazada_en: PropTypes.string, // This is the date when the family invitation was rejected, format: YYYY-MM-DD
});

// PropTypes for user object
export const userPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the user
  username: PropTypes.string.isRequired, // This is the username of the user
  correo: PropTypes.string.isRequired, // This is the email of the user
  nombre: PropTypes.string, // This is the name of the user
  apellido: PropTypes.string, // This is the last name of the user
  familia: PropTypes.shape(simplifiedFamilyPropType), // This is the family of the user
  fecha_de_nacimiento: PropTypes.string, // This is the birth date of the user, format: YYYY-MM-DD
});

// PropTypes for family object
export const familyPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the family
  nombre: PropTypes.string.isRequired, // This is the name of the family
  admin: PropTypes.shape(userPropType).isRequired, // This is the admin of the family
  miembros: PropTypes.arrayOf(userPropType), // This is the list of members of the family
  invitaciones: PropTypes.arrayOf(familyInvitationPropType), // This is the list of invitations for the family
});

// PropTypes for account type object
export const accountTypePropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the account type
  nombre: PropTypes.string.isRequired, // This is the name of the account type
});

// PropTypes for Custody Agent type object
export const custodyAgentTypePropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the custody agent type
  nombre: PropTypes.string.isRequired, // This is the name of the custody agent type
  tipos_de_cuenta: PropTypes.arrayOf(accountTypePropType).isRequired, // This is the list of account types of the custody agent type
});

// PropTypes for custody agent object
export const custodyAgentPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the custody agent
  nombre: PropTypes.string.isRequired, // This is the name of the custody agent
  sub: PropTypes.string.isRequired, // This represents the username of the owner of the custody agent
  tipo: PropTypes.shape(custodyAgentTypePropType), // This is the type of the custody agent
  pais: PropTypes.shape(countryPropType), // This is the country of the custody agent
  usuario: PropTypes.shape(userPropType), // This is the user of the custody agent
})

// PropTypes for account object
export const accountPropType = PropTypes.shape({
  id: PropTypes.number, // This is the id of the account
  nombre: PropTypes.string.isRequired, // This is the name of the account
  numero: PropTypes.string.isRequired, // This is the number of the account
  agente_de_custodia: PropTypes.oneOfType([PropTypes.shape(custodyAgentPropType).isRequired, null]), // This is the custody agent of the account
  balance: PropTypes.number.isRequired, // This is the balance of the account
  sub: PropTypes.string.isRequired, // This represents the username of the owner of the account
  tipo: PropTypes.string.isRequired, // This is the type of the account
  moneda: PropTypes.shape(currencyPropType).isRequired,
  tipo_de_cuenta: PropTypes.shape(accountTypePropType).isRequired,
  creada_en: PropTypes.string, // Optional, this is the date when the account was created
  ultima_modificacion: PropTypes.string, // Optional, this is the date when the account was last modified
});

// PropTypes for card balance object
export const cardBalancePropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the card balance
  tarjeta_id: PropTypes.number.isRequired, // This is the id of the card
  balance: PropTypes.number.isRequired, // This is the balance of the card
  moneda: PropTypes.shape(currencyPropType).isRequired, // This is the currency of the card
  creada_en: PropTypes.string, // Optional, this is the date when the card balance was created
  ultima_modificacion: PropTypes.string, // Optional, this is the date when the card balance was last modified
});

// PropTypes for card object
export const cardPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the card
  fecha_de_cierre_actual: PropTypes.string.isRequired, // This is the current closing date of the card, format: YYYY-MM-DD
  fecha_de_vencimiento_actual: PropTypes.string.isRequired, // This is the current expiration date of the card, format: YYYY-MM-DD
  moneda: PropTypes.shape(currencyPropType).isRequired, // This is the currency of the card
  sub: PropTypes.string, // This represents the username of the owner of the card
  nombre: PropTypes.string, // This is the name of the card
  agente_de_custodia: PropTypes.shape(custodyAgentPropType), // This is the custody agent of the card
  numero: PropTypes.string, // This is the number of the card
  tope_de_credito: PropTypes.number, // This is the credit limit of the card
  saldos_de_tarjeta: PropTypes.arrayOf(cardBalancePropType), // This is the list of card balances of the card
});

// Card account state object
export const cardAccountStatePropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the card account state
  tarjeta_id: PropTypes.number.isRequired, // This is the id of the card
  fecha_de_cierre: PropTypes.string.isRequired, // This is the closing date of the card, format: YYYY-MM-DD
  fecha_de_vencimiento: PropTypes.string.isRequired, // This is the expiration date of the card, format: YYYY-MM-DD
  creada_en: PropTypes.string, // Optional, this is the date when the card account state was created
  ultima_modificacion: PropTypes.string, // Optional, this is the date when the card account state was last modified
  cerrado: PropTypes.bool.isRequired, // This is the status of the card account state
});


// PropTypes for card movement object
export const cardMovementPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the movement
  fecha: PropTypes.string.isRequired, // This is the date of the movement, format: YYYY-MM-DD
  monto: PropTypes.number.isRequired, // This is the amount of the movement
  moneda: PropTypes.shape(currencyPropType).isRequired, // This is the currency of the movement
  tarjeta: PropTypes.shape(cardPropType).isRequired, // This is the card of the movement
  categoria: PropTypes.shape(categoryPropType).isRequired, // This is the category of the movement
  estado_de_cuenta: PropTypes.shape(cardAccountStatePropType).isRequired, // This is the card account state of the movement
  comentario: PropTypes.string, // This is the comment of the movement
  etiquetas: PropTypes.arrayOf(labelPropType), // This is the list of labels of the movement
  creada_en: PropTypes.string, // Optional, this is the date when the movement was created
  ultima_modificacion: PropTypes.string, // Optional, this is the date when the movement was last modified
  cuotas_id: PropTypes.number, // Optional, this is the id of the installments of the movement
  numero_de_cuota: PropTypes.number.isRequired, // Optional, this is the number of the installment of the movement, 1 for a single payment
  saldo_de_tarjeta: PropTypes.shape(cardBalancePropType), // Optional, this is the card balance of for the card movement
});


// PropTypes for movement object
export const movementPropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the movement
  fecha: PropTypes.string.isRequired, // This is the date of the movement, format: YYYY-MM-DD
  monto: PropTypes.number.isRequired, // This is the amount of the movement
  moneda: PropTypes.shape(currencyPropType).isRequired, // This is the currency of the movement
  cuenta: PropTypes.shape(accountPropType).isRequired, // This is the account of the movement
  categoria: PropTypes.shape(categoryPropType).isRequired, // This is the category of the movement
  comentario: PropTypes.string, // This is the comment of the movement
  etiquetas: PropTypes.arrayOf(labelPropType), // This is the list of labels of the movement
  creada_en: PropTypes.string, // Optional, this is the date when the movement was created
  ultima_modificacion: PropTypes.string, // Optional, this is the date when the movement was last modified
  valido: PropTypes.bool, // Optional, this is the status of the movement
});

// PropTypes for a PayPal plan
export const paypalPlanPropType = PropTypes.shape({
  id: PropTypes.string.isRequired, // This is the id of the PayPal plan
  nombre: PropTypes.string.isRequired, // This is the name of the PayPal plan
  monto: PropTypes.number.isRequired, // This is the price of the PayPal plan
  moneda: PropTypes.string.isRequired, // This is the currency of the PayPal plan
  frecuencia: PropTypes.string.isRequired, // This is the frequency of the PayPal plan
  intervalo: PropTypes.number.isRequired, // This is the interval of the PayPal plan
  descripcion: PropTypes.string.isRequired, // This is the description of the PayPal plan
  producto: PropTypes.string.isRequired, // This is the id of the PayPal product of the PayPal plan
});

// PropTypes for a PayPal product
export const paypalProductPropType = PropTypes.shape({
  id: PropTypes.string.isRequired, // This is the id of the PayPal product
  nombre: PropTypes.string.isRequired, // This is the name of the PayPal product
  descripcion: PropTypes.string.isRequired, // This is the description of the PayPal product
});


// PropTypes for a Promotional Code
export const promotionalCodePropType = PropTypes.shape({
  id: PropTypes.number.isRequired, // This is the id of the Promotional Code
  codigo: PropTypes.string.isRequired, // This is the code of the Promotional Code
  usuario_id: PropTypes.number.isRequired, // This is the id of the user of the Promotional Code
  usuario: PropTypes.shape(userPropType).isRequired, // This is the user of the Promotional Code
  planes_de_suscripcion: PropTypes.arrayOf(paypalPlanPropType).isRequired, // This is the list of PayPal plans of the Promotional Code
});


export const productPropType = PropTypes.shape({
  nombre: PropTypes.string.isRequired,
  proveedor: PropTypes.string.isRequired,
  descripcion: PropTypes.string,
}).isRequired

