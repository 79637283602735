// React imports
import React from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { IconButton, Stack, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

// My component imports
import { paypalProductPropType } from '../../utils/myPropTypes';

function PayPalProductListItem(props) {

  // Destructure props
  const { product, onModify, onDelete } = props;

  return <Stack direction="row" onClick={() => onModify(product)}>
    <Stack>
      <Typography variant="h6">{product.nombre}</Typography>
      <Stack direction="row" spacing={1}>
        <Typography variant="subtitle1">{`Proveedor: ${product.proveedor}`}</Typography>
        <Typography variant="subtitle2">{`Id: ${product.id}`}</Typography>
      </Stack>

      <Typography variant="subtitle1">{`Descripción: ${product.descripcion}`}</Typography>
    </Stack>
    <Stack flexGrow={1} justifyContent="center" alignItems="flex-end">
      <IconButton edge="end" aria-label="delete" onClick={(e) => { e.stopPropagation(); onDelete(product); }}>
        <DeleteIcon fontSize='large' />
      </IconButton>
    </Stack>
  </Stack >
}

PayPalProductListItem.propTypes = {
  product: paypalProductPropType.isRequired,
  onModify: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PayPalProductListItem;