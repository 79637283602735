// PropTypes imports
import PropTypes from "prop-types";

// MUI imports
import { Stack } from "@mui/material";

// My components imports
import { promotionalCodePropType } from "../../utils/myPropTypes";



function PromotionaCodeListItem({ code, onClick }) {
  return (
    <Stack onClick={() => onClick(code)}>
      <p>{code.codigo}</p>
    </Stack>
  )
}

PromotionaCodeListItem.propTypes = {
  code: promotionalCodePropType.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default PromotionaCodeListItem;

