// React router imports
import { useNavigate } from "react-router-dom";

// MUI imports
import Stack from '@mui/material/Stack';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

// My components imports
import MyPaper from '../basic/myPaper';
import { urls } from "../../settings";

function ErrorPage() {
  const navigate = useNavigate();
  return (
    <Stack direction="row" justifyContent="center">
      <MyPaper>
        <Stack spacing={1}>
          <Alert severity="error"><div>Parece que hubo un error</div><SentimentVeryDissatisfiedIcon fontSize="large" /></Alert>
          <Button variant="contained" onClick={() => navigate(urls.loginUrl)}>Volver al login</Button>
        </Stack>
      </MyPaper>
    </Stack>)
}

function ErrorMessage() {
  return (
    <Stack direction="row" justifyContent="center">
      <MyPaper>
        <Stack spacing={1}>
          <Alert severity="error"><div>Parece que hubo un error</div><SentimentVeryDissatisfiedIcon fontSize="large" /></Alert>
        </Stack>
      </MyPaper>
    </Stack>)
}

export default ErrorPage;
export { ErrorMessage };