// MUI imports
import { Paper } from "@mui/material";
import { styled } from '@mui/system';

function MyPaper(props) {

  const ObjectToReturn = styled(Paper)(({ theme }) => ({
    padding: '10px',
    maxWidth: '600px',
    width: '90vw',
    marginBottom: '5px !important',
    // margin: '10px',
  }));

  return <ObjectToReturn elevation={props.shallow ? 0 : 1}>
    {props.children}
  </ObjectToReturn>;

}


export default MyPaper;