// React imports
import { useState, useEffect } from "react";

// React router imports
import { useNavigate } from "react-router-dom";

// MUI imports
import { Alert, Button, Stack, Typography } from "@mui/material";
import { List, ListItemAvatar, Avatar, ListItemText } from "@mui/material";
import PaidIcon from '@mui/icons-material/Paid';
import CategoryIcon from '@mui/icons-material/Category';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

// My components imports
import MyPaper from "../basic/myPaper.js";
import MyMenuComponent from "./myMenuComponent.js";
import { urls } from "../../settings.js";
import { syncPayPalWithDb } from "../../utils/adminApi.js";
import MyLoadingList from "../basic/myLoadingList.js";

function SyncPayPalWithDbPage() {

  // Auxiliary functions
  const syncFunction = async () => {
    setLoading(true);
    setError(false);
    try {
      await syncPayPalWithDb();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }

  // State constants
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  // Handlers
  const syncAgainHandler = async event => {
    await syncFunction();
  }

  return <MyMenuComponent
    links={[
      { url: urls.plansAndSubscriptionsUrl, name: 'Planes y sucripciones' },
    ]}
    currentPageName={'Sync'}
  >
    <MyPaper>
      <Stack spacing={1}>
        {loading ? <MyLoadingList /> : (error ? <Alert severity="error">Error al sincronizar</Alert> : <Alert severity="success">Sincronización exitosa</Alert>)}
        <Button variant="contained" color="primary" onClick={syncAgainHandler} fullWidth>Sincronizar</Button>
      </Stack>
    </MyPaper>
  </MyMenuComponent>;
}

export default SyncPayPalWithDbPage;