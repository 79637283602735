// React imports
import { useState } from "react";

// PropTypes imports
import PropTypes from "prop-types";

// My components imports
import ProductForm from "../forms/productForm";
import { EMPTY_PRODUCT } from "../../utils/constants";
import { productPropType } from "../../utils/myPropTypes";
import { createProduct } from "../../utils/adminApi";



export default function CreateModifyProduct({ initialProduct = EMPTY_PRODUCT, onCancel, onSubmit }) {

  // Handlers
  const handleSubmit = (product) => {
    console.log('Submit:', product);
    createProduct(product)
      .then(response => {
        console.log(response);
        onSubmit(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  return <ProductForm product={initialProduct} onSubmit={handleSubmit} onCancel={onCancel} />

}

CreateModifyProduct.propTypes = {
  initialProduct: productPropType,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  intent: PropTypes.oneOf(['create', 'modify']), // 'create' or 'modify', default is 'create'
}

