// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Stack, Typography } from "@mui/material";

// My components imports
import MyPaper from "../basic/myPaper.js";
import MyMenuComponent from "./myMenuComponent.js";
import PayPalPlansList from "../lists/paypalPlansList.js";
import MyLoadingList from "../basic/myLoadingList.js";
import CreateModifyPaypalPlan from "./createModifyPaypalPlan.js";
import AddButton from "../basic/addButton.js";
import MyDialog from "../basic/myDialog.js";
import { getAllPayPalPlans, getProducts, deactivatePayPalPlan, activatePayPalPlan } from "../../utils/adminApi.js";
import { emptyPayPalPlan } from "../../utils/constants.js";
import { ErrorMessage } from "./errorPage.js";
import { urls } from "../../settings.js";


function PlansPage() {

  // State constants
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [currentIntent, setCurrentIntent] = useState('create');
  const [currentPlan, setCurrentPlan] = useState(emptyPayPalPlan);
  const [ready, setReady] = useState(false);
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(false);

  // Auxiliary functions
  const udpatePlans = () => {
    setLoading(true);
    setError(false);
    setReady(false);
    setShowDialog(false);
    Promise.all([getAllPayPalPlans(), getProducts()])
      .then(responses => {
        console.log('Setting plans:', responses[0].results);
        setPlans(responses[0].results);
        console.log('Setting products:', responses[1].results);
        setProducts(responses[1].results);
      })
      .catch(error => {
        console.log(error);
        setError(true);
      });
  }

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      udpatePlans();
    }
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setLoading(false);
    }
    return () => { isMounted = false };
  }, [products, plans, error]);

  useEffect(() => {
    let isMounted = true;
    if (ready && isMounted) {
      setShowDialog(true);
    }
    return () => { isMounted = false };
  }, [currentPlan, ready, currentIntent]);

  // Handlers
  const handleModify = plan => {
    setCurrentIntent('modify');
    setCurrentPlan(plan);
    setReady(true);
  }

  const handleCreate = () => {
    setCurrentIntent('create');
    setCurrentPlan(emptyPayPalPlan);
    setReady(true);
  }

  const handleCancel = () => {
    setShowDialog(false);
    setReady(false);
    setCurrentPlan(emptyPayPalPlan);
  }

  const handleActivate = plan => {
    setLoading(true);
    activatePayPalPlan(plan)
      .then(response => {
        console.log('Plan activated:', response);
        udpatePlans();
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleDeactivate = plan => {
    setLoading(true);
    deactivatePayPalPlan(plan)
      .then(response => {
        console.log('Plan deactivated:', response);
        udpatePlans();
      })
      .catch(error => {
        console.log(error);
      });
  }



  return <MyMenuComponent
    links={[
      { url: urls.plansAndSubscriptionsUrl, name: 'Planes y suscripciones' },
    ]}
    currentPageName={'Planes'}
  >
    <MyPaper>
      {loading ? <MyLoadingList /> : (error ? <ErrorMessage /> :
        (plans.length > 0 && products.length > 0 ? <Stack>
          <MyDialog
            open={showDialog}
            onClose={handleCancel}
            title={currentIntent === 'create' ? 'Crear plan' : 'Modificar plan'}
          >
            <CreateModifyPaypalPlan
              initialValues={{ intent: currentIntent, plan: currentPlan, products: products }}
              onSubmit={plan => udpatePlans()}
              onCancel={handleCancel}
            />
          </MyDialog>
          <PayPalPlansList plans={plans} onModify={handleModify} onActivate={handleActivate} onDeactivate={handleDeactivate} />
          <AddButton onClick={handleCreate} />
        </Stack> : <Typography variant='h6'>No hay planes registrados</Typography>)
      )}
    </MyPaper>
  </MyMenuComponent>;
}

export default PlansPage;