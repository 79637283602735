const awsConfiguration = {
  // OPTIONAL - Hosted UI configuration
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  region: process.env.REACT_APP_AWS_REGION,
  oauth: {
    region: process.env.REACT_APP_AWS_REGION,
    domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN,
    scope: ['email', 'openid', 'profile'],
    // scope: ['email', 'profile', 'openid'],
    // redirectSignIn: 'https://devlocal.finanzasuy.com:3000/',
    // redirectSignOut: 'https://devlocal.finanzasuy.com:3000/signout',
    redirectSignIn: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_AWS_COGNITO_REDIRECT_SIGN_OUT,
    responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
  }
};

const googleConfiguration = {
  clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
};

const urls = {
  homeUrl: "/",
  plansAndSubscriptionsUrl: "/plans_and_subscriptions",
  plansUrl: "/plans",
  productsUrl: "/products",
  promotionalCodesUrl: "/promotional_codes",
  synchronizePayPalUrl: "/synchronize_paypal",
};

const apiUrl = process.env.REACT_APP_API_URL;

const appName = process.env.REACT_APP_APP_NAME;

const adminApiUrl = apiUrl + '/admin';

const endpointNames = {
  login: '/login',
  perfil: '/perfil',
  usuarios: '/usuarios',
  cuentas: '/cuentas',
  monedas: '/monedas',
  categorias: '/categorias',
  tarjetas: '/tarjetas',
  movimientos: '/movimientos',
  movimientosTarjeta: '/movimientos_tarjeta',
  transferencias: '/transferencias',
  etiquetas: '/etiquetas',
  saldosDeTarjeta: '/saldos_de_tarjeta',
  estadosDeCuenta: '/estados_de_cuenta',
  cotizaciones: '/cotizaciones',
  pagosDeTarjeta: '/pagos_de_tarjeta',
  gastos: '/gastos',
  cashflow: '/cashflow',
  balance: '/balance',
  agentesDeCustodia: '/agentes_de_custodia',
  tiposDeAgente: '/tipos_de_agente',
  paises: '/paises',
  familias: '/familias',
  invitaciones: '/invitaciones',
  revocar: '/revocar',
  aceptar: '/aceptar',
  rechazar: '/rechazar',
  miembrosDeFamilia: '/miembros',
  refreshToken: '/refresh_token',
  syncPayPalWithDb: '/sync/paypal',
}

const apiEndpoints = {
  login: apiUrl + endpointNames.login,
  perfil: apiUrl + endpointNames.perfil,
  usuarios: apiUrl + endpointNames.usuarios,
  cuentas: apiUrl + endpointNames.cuentas,
  monedas: apiUrl + endpointNames.monedas,
  categorias: apiUrl + endpointNames.categorias,
  tarjetas: apiUrl + endpointNames.tarjetas,
  movimientos: apiUrl + endpointNames.movimientos,
  movimientosTarjeta: apiUrl + endpointNames.movimientosTarjeta,
  transferencias: apiUrl + endpointNames.transferencias,
  etiquetas: apiUrl + endpointNames.etiquetas,
  saldosDeTarjeta: apiUrl + endpointNames.saldosDeTarjeta,
  estadosDeCuenta: apiUrl + endpointNames.estadosDeCuenta,
  pagosDeTarjeta: apiUrl + endpointNames.pagosDeTarjeta,
  gastos: apiUrl + endpointNames.gastos,
  cashflow: apiUrl + endpointNames.cashflow,
  balance: apiUrl + endpointNames.balance,
  agentesDeCustodia: apiUrl + endpointNames.agentesDeCustodia,
  tiposDeAgente: apiUrl + endpointNames.tiposDeAgente,
  paises: apiUrl + endpointNames.paises,
  familias: apiUrl + endpointNames.familias,
  invitaciones: apiUrl + endpointNames.invitaciones,
  revocarInvitaciones: apiUrl + endpointNames.invitaciones + endpointNames.revocar,
  aceptarInvitaciones: apiUrl + endpointNames.invitaciones + endpointNames.aceptar,
  rechazarInvitaciones: apiUrl + endpointNames.invitaciones + endpointNames.rechazar,
  refreshToken: apiUrl + endpointNames.refreshToken,
}

const adminApiEndpoints = {
  users: adminApiUrl + '/usuarios',
  usersStats: adminApiUrl + '/stats/usuarios',
  activityStats: adminApiUrl + '/stats/actividad',
  paypalPlans: adminApiUrl + '/subscriptions/plans',
  products: adminApiUrl + '/subscriptions/products',
  syncPayPalWithDb: adminApiUrl + endpointNames.syncPayPalWithDb,
}

export default awsConfiguration;
export { urls, apiEndpoints, endpointNames, googleConfiguration, appName, adminApiEndpoints, adminApiUrl };

