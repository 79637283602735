// MUI imports
import { Stack, Typography, Avatar } from "@mui/material";

// My component imports
import { userPropType } from "../../utils/myPropTypes";

function UsuarioListItem(props) {

  // Destructure props
  const { usuario } = props;

  return <Stack direction="row" spacing={2} sx={{ cursor: "pointer" }}>
    <Avatar alt={usuario.nombre} src={usuario.perfil.picture} />

    <Stack direction="column" justifyContent="center">
      <Typography variant="body1">{usuario.correo}</Typography>
    </Stack>

  </Stack>
}

UsuarioListItem.propTypes = {
  usuario: userPropType,
};

export default UsuarioListItem;