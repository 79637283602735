// My components imports
import { apiEndpoints, endpointNames } from '../settings.js';
import { getSub, getAccessToken, saveTokens } from "./authUtils.js";
import { purgeQueryObject } from "./misc.js";
import { apiDateSerializator } from './date.js';

// Auxiliary functions
function HTTPException(code, text) {
  this.code = code;
  this.text = text;
}

async function get(url, errorMessage, queryObject) {
  // todo: implementar pagination
  const jwtToken = await getAccessToken();
  const completeUrl = queryObject ? url + '?' + new URLSearchParams(purgeQueryObject(queryObject)).toString() : url;
  // Executing the request
  const response = await fetch(completeUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + jwtToken,
    },
  })

  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return {
      results: [],
      ok: false,
      code: 404,
    };
  } else {
    throw new Error(errorMessage);
  }
}

function postPutGenerator(method) {
  return async (url, body, error, unauthenticated = false) => {
    const jwtToken = unauthenticated ? '' : await getAccessToken();
    const response = await fetch(url, {
      method: method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken,
      },
      body: JSON.stringify(body)
    });

    if (response.ok) {
      return response.json();
    } else {
      const errorObject = await response.json();
      // throw new Error(error + ', ' + JSON.stringify(specificError));
      throw new HTTPException(response.status, errorObject.error);
    }
  }
}

const post = postPutGenerator('POST');

const put = postPutGenerator('PUT');

async function deleteResource(url, errorMessage) {
  // todo: implementar pagination

  const jwtToken = await getAccessToken();

  // Executing the request
  const response = await fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + jwtToken,
    }
  })

  if (response.ok) {
    return true;
  } else {
    throw new Error(errorMessage);
  }
}

// Api specific methods
async function getUser() {
  let sub = await getSub();
  const url = apiEndpoints.usuarios + '/' + sub;
  return get(url, 'Error getting user');
}

async function getUserProfile() {
  const sub = await getSub();
  const url = apiEndpoints.usuarios + '/' + sub + endpointNames.perfil;
  return get(url, 'Error getting user profile');
}

async function createFamily(name) {
  const sub = await getSub();
  const url = apiEndpoints.familias;
  const body = {
    nombre: name,
    sub: sub
  }
  return post(url, body, 'Error creating new family');
}

async function modifyFamily(name, familyId) {
  const sub = await getSub();
  const url = apiEndpoints.familias + '/' + familyId;
  const body = {
    nombre: name,
    sub: sub
  }
  return put(url, body, 'Error modifying family');
}

async function getFamily(familyId) {
  const url = apiEndpoints.familias + '/' + familyId;
  return get(url, 'Error getting family');
}

async function deleteFamily(familyId) {
  const url = apiEndpoints.familias + '/' + familyId;
  return deleteResource(url, 'Error leaving family');
}

async function expelFromFamily(familyId, userId) {
  const url = apiEndpoints.familias + '/' + familyId + endpointNames.miembrosDeFamilia + '/' + userId;
  return deleteResource(url, 'Error expelling user from family');
}

async function getFamilyInvitations() {
  const url = apiEndpoints.invitaciones;
  return get(url, 'Error getting family invitations');
}

async function createFamilyInvitation(email, familyId) {
  const url = apiEndpoints.invitaciones;
  const body = {
    correo: email,
    familia_id: familyId
  }
  return post(url, body, 'Error creating family invitation');
}

async function revokeFamilyInvitation(invitationId) {
  const url = apiEndpoints.revocarInvitaciones + '/' + invitationId;
  return put(url, { 'dummy': 'dummy' }, 'Error revoking family invitation');
}

async function getFamilyInvitation(invitationId) {
  const url = apiEndpoints.invitaciones + '/' + invitationId;
  return get(url, 'Error getting family invitation');
}

async function acceptFamilyInvitation(invitationId) {
  const url = apiEndpoints.aceptarInvitaciones + '/' + invitationId;
  return put(url, { 'dummy': 'dummy' }, 'Error accepting family invitation');
}

async function rejectFamilyInvitation(invitationId) {
  const url = apiEndpoints.rechazarInvitaciones + '/' + invitationId;
  return put(url, { 'dummy': 'dummy' }, 'Error rejecting family invitation');
}

async function getUserAccounts(endpoint = 'registrarGasto') {
  const url = apiEndpoints.cuentas + '?endpoint=' + endpoint;
  return get(url, 'Error getting user accounts');
}

async function getUserAccount(accountId) {
  const url = apiEndpoints.cuentas + '/' + accountId;
  return get(url, 'Error getting user account');
}

async function getDebtAccounts() {
  const url = apiEndpoints.cuentas;
  let response = await get(url, 'Error getting debt accounts');
  response.results = response.results.filter(account => account.tipo_de_cuenta.nombre === 'Cuenta de deuda');
  return response;
}

async function createAccount(name, number, initialBalance, currencyId, agenteDeCustodiaId, tipoDeCuentaId) {
  const sub = await getSub();
  const url = apiEndpoints.cuentas;
  const body = {
    nombre: name,
    numero: number,
    balance: initialBalance,
    moneda_id: currencyId,
    agente_de_custodia_id: agenteDeCustodiaId,
    tipo_de_cuenta_id: tipoDeCuentaId,
    sub: sub
  }
  return post(url, body, 'Error creating new account');
}

async function modifyAccount(name, number, balance, currencyId, custodyAgentId, accountTypeId, accountId) {
  const sub = await getSub();
  const url = apiEndpoints.cuentas + '/' + accountId;
  const body = {
    nombre: name,
    numero: number,
    balance: balance,
    moneda_id: currencyId,
    agente_de_custodia_id: custodyAgentId,
    tipo_de_cuenta_id: accountTypeId,
    sub: sub
  }
  return put(url, body, 'Error modifying account');
}

async function deleteAccount(cuentaId) {
  const url = apiEndpoints.cuentas + '/' + cuentaId;
  return deleteResource(url, 'Error modifying account');
}

async function getCards() {
  const url = apiEndpoints.tarjetas;
  return get(url, 'Error getting cards.')
}

async function getCard(cardId) {
  const url = apiEndpoints.tarjetas + '/' + cardId;
  return get(url, 'Error getting card.')
}

async function createCard(name, closeDate, dueDate, currencyId, number, creditLimit, custodyAgentId) {
  const sub = await getSub();
  const url = apiEndpoints.tarjetas;
  const body = {
    nombre: name,
    numero: number,
    fecha_de_cierre_actual: apiDateSerializator(closeDate),
    fecha_de_vencimiento_actual: apiDateSerializator(dueDate),
    moneda_id: currencyId,
    tope_de_credito: creditLimit,
    sub: sub,
    agente_de_custodia_id: custodyAgentId
  }
  return post(url, body, 'Error creating new card');
}

async function modifyCard(name, closeDate, dueDate, currencyId, number, creditLimit, custodyAgentId, cardId) {
  const sub = await getSub();
  const url = apiEndpoints.tarjetas + '/' + cardId;
  const body = {
    nombre: name,
    numero: number,
    moneda_id: currencyId,
    fecha_de_cierre_actual: apiDateSerializator(closeDate),
    fecha_de_vencimiento_actual: apiDateSerializator(dueDate),
    tope_de_credito: creditLimit,
    sub: sub,
    agente_de_custodia_id: custodyAgentId
  }
  return put(url, body, 'Error modifying card');
}

async function getCardBalances(cardId) {
  const url = apiEndpoints.tarjetas + '/' + cardId + endpointNames.saldosDeTarjeta;
  return get(url, 'Error getting card balances.')
}

async function getCardAccountStates(cardId, queryObject) {
  let url = apiEndpoints.tarjetas + '/' + cardId + endpointNames.estadosDeCuenta;
  return get(url, 'Error getting card account states', queryObject)
}

async function getCardAccountState(stateId) {
  let url = apiEndpoints.estadosDeCuenta + '/' + stateId;
  return get(url, 'Error getting card account state.')
}

async function modifyCardAccountState(cardId, stateId, closeDate, dueDate) {
  let url = apiEndpoints.estadosDeCuenta + '/' + stateId;
  const body = {
    tarjeta_id: cardId,
    fecha_de_cierre: apiDateSerializator(closeDate),
    fecha_de_vencimiento: apiDateSerializator(dueDate),
  }
  return put(url, body, 'Error modifying account state');
}

async function modifyCardBalance(tarjetaId, balanceId, newBalance, monedaId) {
  const sub = await getSub();
  const url = apiEndpoints.saldosDeTarjeta + '/' + balanceId;
  const body = {
    tarjeta_id: tarjetaId,
    balance: newBalance,
    moneda_id: monedaId,
    sub: sub
  }
  return put(url, body, 'Error modifying card');
}

async function createCardPayment(cardBalanceId, accountId, dstAmmount, srcAmmount, date, comment, labelsIds) {
  const url = apiEndpoints.pagosDeTarjeta;
  const body = {
    saldo_de_tarjeta_id: cardBalanceId,
    cuenta_id: accountId,
    monto_destino: dstAmmount,
    monto_origen: srcAmmount,
    fecha: apiDateSerializator(date),
    comentario: comment,
    etiquetas_ids: labelsIds
  }
  return post(url, body, 'Error creating new cardPayment');
}

async function getCardPayments(queryObject) {
  const url = apiEndpoints.pagosDeTarjeta;
  return get(url, 'Error getting card payments.', queryObject);
}

async function modifyCardPayment(cardBalanceId, accountId, dstAmmount, srcAmmount, date, comment, labelsIds, cardPaymentId) {
  const url = apiEndpoints.pagosDeTarjeta + '/' + cardPaymentId;
  const body = {
    saldo_de_tarjeta_id: cardBalanceId,
    cuenta_id: accountId,
    monto_destino: dstAmmount,
    monto_origen: srcAmmount,
    fecha: apiDateSerializator(date),
    comentario: comment,
    etiquetas_ids: labelsIds
  }
  return put(url, body, 'Error modifying new cardPayment');
}

async function deleteCardPayment(cardPaymentId) {
  const url = apiEndpoints.pagosDeTarjeta + '/' + cardPaymentId;
  return deleteResource(url, 'Error deleting card payment');
}

async function getMovements(queryObject) {
  const url = apiEndpoints.movimientos;
  return get(url, 'Error getting movements.', queryObject);
}

async function createMovement(accountId, ammount, categoryId, date, comment, labelsIds) {
  const url = apiEndpoints.movimientos;
  const body = {
    cuenta_id: accountId,
    monto: ammount,
    categoria_id: categoryId,
    fecha: apiDateSerializator(date),
    comentario: comment,
    etiquetas_ids: labelsIds
  }
  return post(url, body, 'Error creating new movement');
}

async function modifyMovement(accountId, ammount, categoryId, date, comment, labelsIds, movementId) {
  const url = apiEndpoints.movimientos + '/' + movementId;
  const body = {
    cuenta_id: accountId,
    monto: ammount,
    categoria_id: categoryId,
    fecha: apiDateSerializator(date),
    comentario: comment,
    etiquetas_ids: labelsIds
  }
  return put(url, body, 'Error modifying new movement');
}

async function deleteMovement(movementId) {
  const url = apiEndpoints.movimientos + '/' + movementId;
  return deleteResource(url, 'Error deleting movement');
}

async function getTransfers(queryObject) {
  const url = apiEndpoints.transferencias;
  return get(url, 'Error getting transfers.', queryObject)
}

async function createTransfer(srcAcctId, srcAmmount, dstAcctId, dstAmmount, date, comment, labelsIds) {
  const url = apiEndpoints.transferencias;
  const body = {
    cuenta_origen_id: srcAcctId,
    monto_origen: srcAmmount,
    cuenta_destino_id: dstAcctId,
    monto_destino: dstAmmount,
    fecha: apiDateSerializator(date),
    comentario: comment,
    etiquetas_ids: labelsIds
  }
  return post(url, body, 'Error creating new transfer');
}

async function modifyTransfer(transferId, srcAcctId, srcAmmount, dstAcctId, dstAmmount, date, comment, labelsIds) {
  const url = apiEndpoints.transferencias + '/' + transferId;
  const body = {
    cuenta_origen_id: srcAcctId,
    monto_origen: srcAmmount,
    cuenta_destino_id: dstAcctId,
    monto_destino: dstAmmount,
    fecha: apiDateSerializator(date),
    comentario: comment,
    etiquetas_ids: labelsIds
  }
  return put(url, body, 'Error modifying new transfer');
}

async function deleteTransfer(transferId) {
  const url = apiEndpoints.transferencias + '/' + transferId;
  return deleteResource(url, 'Error deleting transfer');
}

async function createCardMovement(cardId, ammount, currencyId, categoryId, date, installments, comment, labelsIds) {
  const url = apiEndpoints.movimientosTarjeta;
  const body = {
    tarjeta_id: cardId,
    monto: ammount,
    moneda_id: currencyId,
    categoria_id: categoryId,
    fecha: apiDateSerializator(date),
    cantidad_de_cuotas: installments,
    comentario: comment,
    etiquetas_ids: labelsIds
  }
  return post(url, body, 'Error creating new cardMovement');
}

async function deleteCardMovement(movementId) {
  const url = apiEndpoints.movimientosTarjeta + '/' + movementId;
  return deleteResource(url, 'Error deleting Card Movement');
}

async function getCardMovements(queryObject) {
  const url = apiEndpoints.movimientosTarjeta;
  return get(url, 'Error getting Card movements.', queryObject)
}

async function getCurrencies() {
  const url = apiEndpoints.monedas;
  return get(url, 'Error getting currencies.')
}

async function getCategories() {
  const url = apiEndpoints.categorias;
  return get(url, 'Error getting categories.')
}

async function getLabels() {
  const url = apiEndpoints.etiquetas;
  return get(url, 'Error getting labels.')
}

async function createLabel(text) {
  const sub = await getSub();
  const url = apiEndpoints.etiquetas;
  const body = {
    texto: text,
    sub: sub
  }
  return post(url, body, 'Error creating new label');
}

async function deleteLabel(labelId) {
  const url = apiEndpoints.etiquetas + '/' + labelId;
  return deleteResource(url, 'Error deleting label');
}

async function modifyLabel(labelId, text) {
  const sub = await getSub();
  const url = apiEndpoints.etiquetas + '/' + labelId;
  const body = {
    sub: sub,
    texto: text
  }
  return put(url, body, 'Error modifying label');
}

async function apiLogin(token) {
  const url = apiEndpoints.login;
  const body = { token: token }
  let tokens = await post(url, body, 'Error in API login', true);
  saveTokens({ tokens });
  return tokens;
}

async function modifyProfile(body) {
  const sub = await getSub();
  const url = apiEndpoints.usuarios + '/' + sub + endpointNames.perfil;
  return put(url, body, 'Error modifying profile');
}

async function getCurrencyExchange(currencyId, queryObject) {
  const url = apiEndpoints.monedas + '/' + currencyId + endpointNames.cotizaciones;
  return get(url, 'Error getting currency quote', queryObject);
}

async function getExpenses(queryObject) {
  const url = apiEndpoints.gastos;
  return get(url, 'Error getting expenses.', queryObject);
}

async function getCashflow(queryObject) {
  const url = apiEndpoints.cashflow;
  return get(url, 'Error getting cashflow.', queryObject);
}

async function getBalance(currencyId) {
  // CurrencyId is passed as query parameter
  const url = apiEndpoints.balance + '?currency=' + currencyId;
  return get(url, 'Error getting balance.');
}

async function getCustodyAgents(queryObject) {
  let urlParams = new URLSearchParams();
  ['Banco', 'Lugar físico', 'Corredor de bolsa'].forEach((tipo) => {
    urlParams.append('tipos_de_agentes', tipo);
  });
  const url = apiEndpoints.agentesDeCustodia + '?' + urlParams.toString();
  return get(url, 'Error getting custodyAgents.', queryObject);
}

async function getDebtCustodyAgents(queryObject) {
  let urlParams = new URLSearchParams();
  ['Deudas'].forEach((tipo) => {
    urlParams.append('tipos_de_agentes', tipo);
  });
  const url = apiEndpoints.agentesDeCustodia + '?' + urlParams.toString();
  return get(url, 'Error getting custodyAgents.', queryObject);
}

async function getCustodyAgent(custodyAgentId) {
  const url = apiEndpoints.agentesDeCustodia + '/' + custodyAgentId;
  return get(url, 'Error getting custodyAgent.');
}

async function createCustodyAgent(name, typeId, countryId) {
  const sub = await getSub();
  const url = apiEndpoints.agentesDeCustodia;
  const body = {
    sub: sub,
    nombre: name,
    tipo_id: typeId,
    pais_id: countryId
  }
  return post(url, body, 'Error creating new custodyAgent');
}

async function modifyCustodyAgent(name, typeId, countryId, custodyAgentId) {
  const url = apiEndpoints.agentesDeCustodia + '/' + custodyAgentId;
  const body = {
    nombre: name,
    tipo_id: typeId,
    pais_id: countryId
  }
  return put(url, body, 'Error modifying new custodyAgent');
}

async function deleteCustodyAgent(custodyAgentId) {
  const url = apiEndpoints.agentesDeCustodia + '/' + custodyAgentId;
  return deleteResource(url, 'Error deleting custodyAgent');
}

async function getCustodyAgentsTypes(queryObject) {
  const url = apiEndpoints.tiposDeAgente;
  return get(url, 'Error getting custodyAgentsTypes.', queryObject);
}

async function getCountries(queryObject) {
  const url = apiEndpoints.paises;
  return get(url, 'Error getting countries.', queryObject);
}

async function refreshAccessToken(refreshToken) {
  const url = apiEndpoints.refreshToken;
  const body = { token: refreshToken }
  let newAccessToken = await post(url, body, 'Error refreshing access token', true);
  return newAccessToken;
}


export {
  post,
  get,
  getUser,
  getUserProfile,
  getUserAccounts,
  getUserAccount,
  getDebtAccounts,
  createAccount,
  modifyAccount,
  deleteAccount,
  getCards,
  getCard,
  getCardAccountStates,
  getCardAccountState,
  getCardPayments,
  createCardPayment,
  modifyCardPayment,
  deleteCardPayment,
  modifyCardAccountState,
  getCardBalances,
  createCard,
  modifyCard,
  modifyCardBalance,
  getMovements,
  createMovement,
  modifyMovement,
  deleteMovement,
  getTransfers,
  createTransfer,
  modifyTransfer,
  deleteTransfer,
  createCardMovement,
  deleteCardMovement,
  getCardMovements,
  getCurrencies,
  getCategories,
  getLabels,
  createLabel,
  deleteLabel,
  modifyLabel,
  apiLogin,
  modifyProfile,
  getCurrencyExchange,
  getExpenses,
  getCashflow,
  getBalance,
  getCustodyAgents,
  getCustodyAgent,
  createCustodyAgent,
  modifyCustodyAgent,
  deleteCustodyAgent,
  getCustodyAgentsTypes,
  getDebtCustodyAgents,
  getCountries,
  createFamily,
  modifyFamily,
  getFamily,
  deleteFamily,
  deleteFamily as leaveFamily,
  expelFromFamily,
  getFamilyInvitations,
  createFamilyInvitation,
  revokeFamilyInvitation,
  acceptFamilyInvitation,
  rejectFamilyInvitation,
  getFamilyInvitation,
  refreshAccessToken
}