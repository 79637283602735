import dayjs from "dayjs";

function apiDateSerializator(date) {
  let textDate = JSON.stringify(date);
  return textDate.substring(1, 11);
}

// function apiDateTimeSerializator(date) {
//   return dayjs(date).format().split('-03:00').join('')
// }

function apiDateTimeSerializator(date) {
  // Function that transforms a date into a string in the format 'YYYY-MM-DDTHH:MM:SS' (without timezone)
  return dayjs(date).format().replace(/[-+]\d{2}:\d{2}/, '');
}

function getDateFromDateTime(date) {
  // Function that receives a Date object and returns a date in string format 'YYYY-MM-DD'
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
}

function getDateTimeFromDateString(dateString) {
  // Function that receives a date in string format 'YYYY-MM-DD' and returns a Date object
  const year = dateString.substring(0, 4);
  const month = dateString.substring(5, 7);
  const day = dateString.substring(8, 10);
  return new Date(year, month - 1, day);
}

function myGetFullYear(date) {
  // Function that gets full year from a date ignoring timezone
  return parseInt(date.toISOString().substring(0, 4))
}

function myGetMonth(date) {
  // Function that gets full year from a date ignoring timezone
  return parseInt(date.toISOString().substring(5, 7)) - 1
}

function apiDateParser(date) {
  return new Date(date.split('-')[0], date.split('-')[1] - 1, date.split('-')[2]);
}

function dateToDisplayFormat(date) {
  // Receives a date in string format 'YYYY-MM-DD' and transforms it to 'DD/MM/YYYY'
  return date.substring(8, 11) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4);
}

function dateGetFirstDayOfMonth(date) {
  // Receives a date, either in Date format or in "YYYY-MM-DD" and returns a Date object with first day of the month
  const myDate = new Date(date);
  return new Date(myGetFullYear(myDate), myGetMonth(myDate), 1);
}

function dateGetLastDayOfMonth(date) {
  // Receives a date, either in Date format or in "YYYY-MM-DD" and returns a Date object with last day of the month
  const myDate = new Date(date);
  return new Date(myGetFullYear(myDate), myGetMonth(myDate) + 1, 0);
}

function dateGetLastDayOfNextMonth(date) {
  // Receives a date, either in Date format or in "YYYY-MM-DD" and returns a Date object with last day of the month
  const myDate = new Date(date);
  return new Date(myGetFullYear(myDate), myGetMonth(myDate) + 2, 0);
}

function dateGetFirstDayOfPastMonth(date) {
  // Receives a date, either in Date format or in "YYYY-MM-DD" and returns a Date object with first day of last month
  const myDate = new Date(date);
  return new Date(myDate.getFullYear(), myDate.getMonth() - 1, 1);
}

function dateGetLastDayOfPastMonth(date) {
  // Receives a date, either in Date format or in "YYYY-MM-DD" and returns a Date object with last day of last month
  const myDate = new Date(date);
  return new Date(myDate.getFullYear(), myDate.getMonth(), 0);
}

function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export {
  apiDateSerializator,
  apiDateTimeSerializator,
  getDateFromDateTime,
  getDateTimeFromDateString,
  apiDateParser,
  dateToDisplayFormat,
  dateGetFirstDayOfMonth,
  dateGetLastDayOfMonth,
  dateGetLastDayOfNextMonth,
  dateGetLastDayOfPastMonth,
  dateGetFirstDayOfPastMonth,
  monthDiff,
};