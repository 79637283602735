// Proptypes imports
import PropTypes from "prop-types";

// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Stack } from "@mui/material";

// My components imports
import PromotionalCodeForm from "../forms/promotionalCodeForm";
import { userPropType, promotionalCodePropType } from "../../utils/myPropTypes";
import { createPromotionalCode, modifyPromotionalCode } from "../../utils/adminApi";

function CreateModifyPromotionalCode({ initialValues, user, onSubmit, onCancel }) {

  // Handlers
  const handleSubmit = (newCode) => {
    if (initialValues.intent === 'create') {
      createPromotionalCode(newCode.usuario_id, newCode.codigo)
        .then(response => {
          console.log('Nuevo código creado:', response);
          onSubmit(response);
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      modifyPromotionalCode(user.id, initialValues.code.id, newCode.codigo)
        .then(response => {
          console.log('Código modificado:', response);
          onSubmit(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  return (
    <Stack>
      <PromotionalCodeForm user={user} initialCode={initialValues.code.codigo} onSubmit={handleSubmit} intent={initialValues.intent} />
    </Stack>
  );
}

CreateModifyPromotionalCode.propTypes = {
  initialValues: PropTypes.shape({
    intent: PropTypes.oneOf(['create', 'modify']).isRequired,
    code: promotionalCodePropType.isRequired,
  }).isRequired,
  user: userPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default CreateModifyPromotionalCode;