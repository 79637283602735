// React imports
import { useState, useEffect } from "react";

// Proptypes imports
import PropTypes from "prop-types";

// MUI imports
import { Autocomplete, Avatar, Box, Stack, TextField, Typography } from "@mui/material";

// My components imports
import { userPropType } from "../../utils/myPropTypes.js";

function UsuarioPicker({ users, onChange, initialUser }) {

  // State constants
  const [selectedUser, setSelectedUser] = useState(initialUser);

  // Effect hooks
  useEffect(() => {
    if (selectedUser) {
      onChange(selectedUser);
    }
  }, [selectedUser]);

  // Handlers
  const handleChange = (event, newValue) => {
    setSelectedUser(newValue);
  };

  return users &&
    <Autocomplete
      value={selectedUser}
      onChange={handleChange}
      options={users}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.correo}
      renderOption={(props, option) => {
        return (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar alt={option.correo} src={option.perfil.picture} />
              <Typography sx={{ fontSize: 14 }}>{option.correo}</Typography>
            </Stack>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Elige un usuario"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />;

}

UsuarioPicker.propTypes = {
  users: PropTypes.arrayOf(userPropType).isRequired,
  onChange: PropTypes.func.isRequired, // Function to be called when the user changes
  initialUser: userPropType, // Initial user to be selected
}

export default UsuarioPicker;
