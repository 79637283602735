// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Stack } from "@mui/material";

// My component imports
import UsuarioListItem from "../basic/usuarioListItem";
import { userPropType } from "../../utils/myPropTypes";
import MyPaper from "../basic/myPaper";

function UsersList(props) {

  // Destructure props
  const { users } = props;

  return <MyPaper>
    <Stack direction="column" spacing={2} sx={{ padding: '10px' }}>
      {users.map(user => <UsuarioListItem key={user.id} usuario={user} />)}
    </Stack>

  </MyPaper>
}

UsersList.propTypes = {
  users: PropTypes.arrayOf(userPropType),
};

export default UsersList;