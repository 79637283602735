// React imports
import React, { useEffect, useState } from "react";

// Recharts imports
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Legend, CartesianGrid, Tooltip } from 'recharts';

// MUI imports
import { Typography, ButtonGroup, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// My components imports
import MyMenuComponent from "./app/myMenuComponent.js";
import { getActivityStats, getUsers, getUsersStats } from "../utils/adminApi.js";
import UsersList from "./lists/usersList.js";
import MyPaper from "./basic/myPaper.js";




function Home() {

  // State constants
  const [users, setUsers] = useState([]);
  const [usersStats, setUsersStats] = useState([]);
  const [activityStats, setActivityStats] = useState(null);
  const [graphData, setGraphData] = useState([]);
  const [typeOfActivity, setTypeOfActivity] = useState('daily'); // daily or monthly

  // Theme
  const theme = useTheme();

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    getUsers()
      .then(response => {
        if (isMounted) {
          console.log(response);
          setUsers(response.results);
        }
      })
      .catch(error => {
        console.log(error);
      });
    getUsersStats()
      .then(response => {
        if (isMounted) {
          console.log(response);
          setUsersStats(response);
        }
      })
      .catch(error => {
        console.log(error);
      });
    getActivityStats()
      .then(response => {
        if (isMounted) {
          console.log(response);
          setActivityStats(response);
          // construct graph data
          setGraphData(response.stats.last_week.map((item) => { return { name: item.date, actividad: item.activity } }));
        }
      })
      .catch(error => {
        console.log(error);
      });
    return () => { isMounted = false }
  }, [])

  useEffect(() => {
    console.log('Graph data changed', graphData);
  }, [graphData])

  useEffect(() => {
    let isMounted = true;
    if (isMounted && activityStats !== null) {
      // Construct graph data based on type of activity
      if (typeOfActivity === 'daily') {
        setGraphData(activityStats.stats.last_week.map((item) => { return { name: item.date, actividad: item.activity } }));
      } else if (typeOfActivity === 'monthly') {
        setGraphData(activityStats.stats.last_six_months.map((item) => { return { name: item.date, actividad: item.activity } }));
      }
    }
    return () => { isMounted = false }
  }, [typeOfActivity])

  // Handlers
  const handleBarClick = (data, index) => {
    console.log("This is the data", data);
    console.log("This is the index", index);
  }

  return <MyMenuComponent
    links={[
      // { url: urls.homeUrl, name: 'Inicio' },
    ]}
    currentPageName={'Home'}
  >
    <Typography variant="h5">Estadísticas</Typography>
    <ButtonGroup variant="contained" aria-label="outlined primary button group">
      <Button onClick={() => setTypeOfActivity('daily')} variant={typeOfActivity === 'daily' ? 'contained' : 'outlined'}>Diaria</Button>
      <Button onClick={() => setTypeOfActivity('monthly')} variant={typeOfActivity === 'monthly' ? 'contained' : 'outlined'}>Mensual</Button>
    </ButtonGroup>
    <MyPaper>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={graphData}
          margin={{
            top: 5,
            right: 10,
            left: -25,
            bottom: 15,
          }}
        >
          <Tooltip />
          <Bar
            dataKey="actividad"
            fill={theme.palette.primary.main}
            onClick={handleBarClick}
          />
          <CartesianGrid strokeDasharray="1 5" vertical={false} />
          <XAxis dataKey={"name"} tick={{ fontSize: 8, angle: -45, dy: 20 }} />
          <YAxis dataKey={"actividad"} />
          <Legend verticalAlign="top" />
        </BarChart>
      </ResponsiveContainer>
    </MyPaper>

    <Typography variant="h5">Usuarios</Typography>
    <UsersList users={users} />
  </MyMenuComponent>

}

export default Home;
