// React imports
import { useEffect, useState } from "react";

// MUI imports
import { Alert, FormControl, Stack } from "@mui/material";
import CreateModifyPromotionalCode from "./createModifyPromotionalCode";

// My component imports
import MyMenuComponent from "./myMenuComponent";
import { urls } from "../../settings";
import { getPromotionalCodes } from "../../utils/adminApi";
import MyLoadingList from "../basic/myLoadingList";
import MyPaper from "../basic/myPaper";
import AddButton from "../basic/addButton";
import MyDialog from "../basic/myDialog";
import { getUsers } from "../../utils/adminApi";
import UsuarioPicker from "../inputs/usuarioPicker";
import PromotionalCodesList from "../lists/promotionalCodesList";
import { emptyPromotionalCode } from "../../utils/constants";

function PromotionalCodesPage() {

  // State constants
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [error, setError] = useState(false);
  const [codes, setCodes] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [currentIntent, setCurrentIntent] = useState('create');
  const [currentCode, setCurrentCode] = useState(emptyPromotionalCode);
  const [listOfCodesLoaded, setListOfCodesLoaded] = useState(false);
  const [listOfCodesError, setListOfCodesError] = useState(false);

  // Auxiliary functions
  const updateCodes = () => {
    setListOfCodesLoaded(false);
    setListOfCodesError(false);
    setShowDialog(false);
    getPromotionalCodes(selectedUser.id)
      .then(response => {
        console.log('Setting codes:', response.results);
        setCodes(response.results);
        setListOfCodesLoaded(true);
        setListOfCodesError(false);
      })
      .catch(error => {
        console.log(error);
        setListOfCodesError(true);
        setListOfCodesLoaded(true);
      });
  }

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getUsers()
        .then(response => {
          console.log('Setting users:', response.results);
          setUsers(response.results);
          setLoading(false);
        })
        .catch(error => {
          setError(true);
          setLoading(false);
          console.log(error);
        });
    }
    return () => { isMounted = false };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && selectedUser) {
      updateCodes();
    }
    return () => { isMounted = false };
  }, [selectedUser]);


  // Handlers
  const handleCancel = () => {
    setShowDialog(false);
  }

  const handleAddCodeClick = event => {
    setCurrentIntent('create');
    setCurrentCode(emptyPromotionalCode);
    setShowDialog(true);
  }

  const handleModifyCode = code => {
    setCurrentIntent('modify');
    setCurrentCode(code);
    setShowDialog(true);
  }

  return (<MyMenuComponent
    links={[
      { url: urls.plansAndSubscriptionsUrl, name: 'Planes y suscripciones' },
    ]}
    currentPageName={'Códigos promocionales'}
  >
    <MyPaper>
      <Stack spacing={1}>
        {
          loading ? <MyLoadingList /> :
            (
              error ? <div>Error</div> :
                <FormControl fullWidth><UsuarioPicker users={users} onChange={user => setSelectedUser(user)} initialUser={selectedUser} /></FormControl>
            )
        }

        {selectedUser ?
          (
            listOfCodesLoaded ? (
              listOfCodesError ? <Alert severity="error">Error al cargar los códigos</Alert> :
                <PromotionalCodesList codes={codes} onModify={handleModifyCode} />
            ) :
              <MyLoadingList />
          ) : null}

        <MyDialog
          open={showDialog}
          onClose={handleCancel}
          title={currentIntent === 'create' ? 'Crear código' : 'Modificar código'}
        >
          <CreateModifyPromotionalCode
            initialValues={{ intent: currentIntent, code: currentCode }}
            user={selectedUser}
            onSubmit={code => updateCodes()}
            onCancel={handleCancel}
          />
        </MyDialog>

        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <AddButton disabled={selectedUser === null} onClick={handleAddCodeClick} />
        </Stack>
      </Stack>
    </MyPaper>

  </MyMenuComponent>

  );
}

export default PromotionalCodesPage;