// PropTypes imports
import PropTypes from "prop-types";

// MUI imports
import { Alert, Divider, Stack } from "@mui/material";

// My component imports
import PromotionalCodeListItem from "../basic/promotionalCodeListItem";
import { promotionalCodePropType } from "../../utils/myPropTypes";


function PromotionalCodeList({ codes, onModify }) {
  return (
    <Stack>
      {codes.length === 0 && <Alert severity="info">No hay códigos promocionales para este usuario</Alert>}
      {codes.map(code => <><PromotionalCodeListItem code={code} onClick={onModify} /><Divider /></>)}
    </Stack>
  )
}

PromotionalCodeList.propTypes = {
  codes: PropTypes.arrayOf(promotionalCodePropType).isRequired,
  onModify: PropTypes.func.isRequired,
}


export default PromotionalCodeList;