// React imports
import { useState, useEffect } from 'react';

// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

// My component imports
import { paypalPlanPropType, paypalProductPropType, promotionalCodePropType } from '../../utils/myPropTypes';
import { ProductSelectInput } from '../inputs/paypalFormInputs';
import CodigoPicker from '../inputs/codigoPicker';


function PayPalPlanForm(props) {

  // Deconstruct props
  const { plan, intent, products, onCancel, codes } = props;

  // State constants
  const [selectedProduct, setSelectedProduct] = useState(products.filter(product => product.id === plan.id_de_producto)[0]);
  const [name, setName] = useState(plan.nombre);
  const [description, setDescription] = useState(plan.descripcion);
  const [status, setStatus] = useState(plan.status);
  const [frequency, setFrequency] = useState(plan.frecuencia);
  const [interval, setInterval] = useState(plan.intervalo);
  const [amount, setAmount] = useState(plan.monto);
  const [currency, setCurrency] = useState(plan.moneda);
  const [code, setCode] = useState(plan.codigo);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setSelectedProduct(products.filter(product => product.id === plan.id_de_producto)[0]);
      setName(plan.nombre);
      setDescription(plan.descripcion);
      setStatus(plan.status);
      setFrequency(plan.frecuencia);
      setInterval(plan.intervalo);
      setAmount(plan.monto);
      setCurrency(plan.moneda);
    }
    return () => { isMounted = false }
  }, [plan]);

  // Change handlers
  const handleProductChange = (newValue) => {
    console.log('changing product to:', newValue);
    setSelectedProduct(newValue)
  };
  const handleNameChange = event => setName(event.target.value);
  const handleDescriptionChange = event => setDescription(event.target.value);
  const handleStatusChange = event => setStatus(event.target.value);
  const handleFrequencyChange = event => setFrequency(event.target.value);
  const handleIntervalChange = event => setInterval(event.target.value);
  const handleAmountChange = event => setAmount(event.target.value);
  const handleCurrencyChange = event => setCurrency(event.target.value);
  const handleCodeChange = newCode => setCode(newCode);
  const modifyPlanHandler = event => {
    event.preventDefault();
    let data = {
      selectedProduct: selectedProduct,
      nombre: name,
      descripcion: description,
      status: status,
      frecuencia: frequency,
      intervalo: interval,
      monto: amount,
      moneda: currency,
      codigo: code,
    }
    if (intent === 'modify') {
      data.id = plan.id;
    }
    props.onSubmit(data)
  }

  return <div>

    <FormControl sx={{ marginBottom: "5px" }} fullWidth>
      <ProductSelectInput
        products={products}
        onSelect={handleProductChange}
        disabled={intent === 'modify'}
      />
    </FormControl>

    <Stack spacing={1}>
      <FormControl fullWidth>
        <TextField id="name" label="Nombre" variant="filled" value={name} onChange={handleNameChange} />
      </FormControl>

      <FormControl fullWidth>
        <TextField id="description" label="Descripción" variant="filled" value={description} onChange={handleDescriptionChange} />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="status-label">Status</InputLabel>
        <Select
          labelId="status-label"
          id="status"
          value={status}
          label="Status"
          onChange={handleStatusChange}
          disabled={intent === 'modify'}
        >
          <MenuItem value="ACTIVE">ACTIVE</MenuItem>
          <MenuItem value="INACTIVE">INACTIVE</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="frequency-label">Frecuencia</InputLabel>
        <Select
          labelId="frequency-label"
          id="frequency"
          value={frequency}
          label="Frecuencia"
          onChange={handleFrequencyChange}
          disabled={intent === 'modify'}
        >
          <MenuItem value="mes">mes</MenuItem>
          <MenuItem value="año">año</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="interval-label">Intervalo</InputLabel>
        <Select
          labelId="interval-label"
          id="interval"
          value={interval}
          label="Intervalo"
          onChange={handleIntervalChange}
          disabled={intent === 'modify'}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={12}>12</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <TextField id="amount" label="Monto" variant="filled" value={amount} onChange={handleAmountChange} />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="currency-label">Moneda</InputLabel>
        <Select
          labelId="currency-label"
          id="currency"
          value={currency}
          label="Moneda"
          onChange={handleCurrencyChange}
        >
          <MenuItem value="USD">USD</MenuItem>
        </Select>

      </FormControl>

      <FormControl fullWidth>
        <CodigoPicker onChange={handleCodeChange} initialCode={code} codigos={codes} />
      </FormControl>

      <FormControl fullWidth>
        <Button variant="contained" onClick={modifyPlanHandler}>Guardar</Button>
      </FormControl>

      <FormControl fullWidth>
        <Button variant="contained" onClick={onCancel}>Cancelar</Button>
      </FormControl>

    </Stack>
  </div>
}

PayPalPlanForm.propTypes = {
  plan: paypalPlanPropType.isRequired,
  intent: PropTypes.oneOf(['create', 'modify']).isRequired,
  products: PropTypes.arrayOf(paypalProductPropType).isRequired,
  codes: PropTypes.arrayOf(promotionalCodePropType).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export { PayPalPlanForm };