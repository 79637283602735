// React imports
import { useState, cloneElement } from "react";

// React Router imports
import { useNavigate } from "react-router-dom";

// MUI imports
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Drawer from '@mui/material/Drawer';
import Typography from "@mui/material/Typography";
import AppRegistrationTwoToneIcon from '@mui/icons-material/AppRegistrationTwoTone';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

// My components imports
import { urls } from "../../settings.js";
import UserAvatar from "./userAvatar.js";
import { logoutUser } from "../../utils/authUtils.js";
import MyListItem from "./myListItem.js";
import appLogo from "../../static_content/FinanzasUY.png";

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function Menu(props) {

  // State constants
  const [menuOpen, setMenuOpen] = useState(false);

  // Other constants
  const navigate = useNavigate();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ElevationScroll {...props}>
        <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>

            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={event => navigate(urls.homeUrl)}
            >
              <HomeIcon />
            </IconButton>

            <Box sx={{ flexGrow: 1 }} />

            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={() => setMenuOpen(menuState => !menuState)}
            >
              <MenuIcon />
            </IconButton>

          </Toolbar>
        </AppBar>
      </ElevationScroll>

      <Drawer
        anchor={props.position}
        open={menuOpen}
        onClose={(event) => setMenuOpen(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
        >
          <Stack spacing={1}>

            <Stack direction="row" sx={{ margin: "15px" }}>
              <Stack direction="column" spacing={1} sx={{ marginTop: "10px", cursor: "pointer" }} onClick={event => navigate(urls.homeUrl)}>
                <img src={appLogo} alt="FinanzasUY" width={"90%"} />
              </Stack>
              <UserAvatar />
            </Stack>

            <Divider />

            <Stack spacing={1} sx={{ paddingLeft: "10px", paddingRight: "5px" }}>
              <MyListItem onClick={event => navigate(urls.plansAndSubscriptionsUrl)} sx={{ cursor: "pointer" }}>
                <Stack direction="row" alignItems="center" spacing={3}>
                  <SubscriptionsIcon color="primary" fontSize="large" /><Typography color="primary.dark">Planes y suscripciones</Typography>
                </Stack>
              </MyListItem>
            </Stack>

            <Divider />

            <MyListItem onClick={logoutUser} sx={{ cursor: "pointer" }}>
              <Stack direction="row" alignItems="center" spacing={2} sx={{ paddingLeft: "10px" }}>
                <LogoutTwoToneIcon color="primary" fontSize="large" /><Typography color="primary.dark">Cerrar sesión</Typography>
              </Stack>
            </MyListItem>

          </Stack>
        </Box>
      </Drawer >
    </Box >
  )

}

export default Menu;