// My components imports
import { adminApiEndpoints, adminApiUrl } from '../settings.js';
import { getSub, getAccessToken, saveTokens } from "./authUtils.js";
import { purgeQueryObject } from "./misc.js";
import { apiDateSerializator } from './date.js';

// Auxiliary functions
function HTTPException(code, text) {
  this.code = code;
  this.text = text;
}

async function get(url, errorMessage, queryObject) {
  // todo: implementar pagination
  const jwtToken = await getAccessToken();
  const completeUrl = queryObject ? url + '?' + new URLSearchParams(purgeQueryObject(queryObject)).toString() : url;
  // Executing the request
  const response = await fetch(completeUrl, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + jwtToken,
    },
  })

  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return {
      results: [],
      ok: false,
      code: 404,
    };
  } else {
    throw new Error(errorMessage);
  }
}

function postPutGenerator(method) {
  return async (url, body, error, unauthenticated = false) => {
    const jwtToken = unauthenticated ? '' : await getAccessToken();
    const response = await fetch(url, {
      method: method,
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + jwtToken,
      },
      body: JSON.stringify(body)
    });

    if (response.ok) {
      // If 204 do not try to parse the response
      if (response.status === 204) {
        return true;
      } else {
        return response.json();
      }
    } else {
      const errorObject = await response.json();
      // throw new Error(error + ', ' + JSON.stringify(specificError));
      throw new HTTPException(response.status, errorObject.error);
    }
  }
}

const post = postPutGenerator('POST');

const put = postPutGenerator('PUT');

async function deleteResource(url, errorMessage) {
  // todo: implementar pagination

  const jwtToken = await getAccessToken();

  // Executing the request
  const response = await fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + jwtToken,
    }
  })

  if (response.ok) {
    return true;
  } else {
    throw new Error(errorMessage);
  }
}

// API functions

async function getUsers() {
  const url = adminApiEndpoints.users;
  return get(url, 'Error getting users');
}

async function getUsersStats() {
  const url = adminApiEndpoints.usersStats;
  return get(url, 'Error getting users stats');
}

async function getActivityStats() {
  const url = adminApiEndpoints.activityStats;
  return get(url, 'Error getting activity stats');
}

async function getPromotionalCodes(user_id) {
  const url = adminApiEndpoints.users + '/' + user_id + '/codigos_promocionales';
  return get(url, 'Error getting promotional codes');
}

async function getAllPromotionalCodes() {
  const url = adminApiUrl + '/codigos_promocionales';
  return get(url, 'Error getting promotional codes');
}

async function createPromotionalCode(user_id, code) {
  const url = adminApiEndpoints.users + '/' + user_id + '/codigos_promocionales';
  const body = {
    usuario_id: user_id,
    codigo: code,
  };
  return post(url, body, 'Error creating promotional code');
}

async function modifyPromotionalCode(userId, codeId, codeText) {
  const url = adminApiUrl + '/codigos_promocionales/' + codeId;
  console.log('Calling modifyPromotionalCode with the following url:', url);
  const body = {
    usuario_id: userId,
    codigo: codeText,
  };
  return put(url, body, 'Error modifying promotional code');
}

async function getAllPayPalPlans() {
  const url = adminApiEndpoints.paypalPlans;
  return get(url, 'Error getting PayPal plans');
}

async function createPlan(plan) {
  const url = adminApiEndpoints.paypalPlans;
  // modify plan so it complies with API format
  let body = JSON.parse(JSON.stringify(plan));
  body.id_de_producto = plan.selectedProduct.id;
  body.proveedor = plan.selectedProduct.proveedor;
  if (plan.codigo) {
    body.codigo_promocional_id = plan.codigo.id;
  }
  return post(url, body, 'Error creating PayPal plan');
}

async function deactivatePayPalPlan(plan) {
  const url = adminApiEndpoints.paypalPlans + '/' + plan.id;
  return deleteResource(url, 'Error deactivating PayPal plan');
}

async function activatePayPalPlan(plan) {
  const url = adminApiEndpoints.paypalPlans + '/' + plan.id;
  return post(url, plan, 'Error activating PayPal plan');
}

async function modifyPlan(plan) {
  const url = adminApiEndpoints.paypalPlans + '/' + plan.id;
  // modify plan so it complies with API format
  let body = JSON.parse(JSON.stringify(plan));
  body.id_de_producto = plan.selectedProduct.id;
  body.proveedor = plan.selectedProduct.proveedor;
  if (plan.codigo) {
    body.codigo_promocional_id = plan.codigo.id;
  }
  console.log('modifying plan with the following body:', body);
  return put(url, body, 'Error modifying PayPal plan');
}

async function syncPayPalWithDb() {
  const url = adminApiEndpoints.syncPayPalWithDb;
  return post(url, {}, 'Error syncing PayPal with DB');
}

async function createProduct(product) {
  const url = adminApiEndpoints.products;
  console.log('Creating product with the following url:', url, 'and the following product:', product);
  return post(url, product, 'Error creating product');
}

async function getProducts() {
  const url = adminApiEndpoints.products;
  return get(url, 'Error getting products');
}

export {
  getUsers,
  getUsersStats,
  getActivityStats,
  getPromotionalCodes,
  getAllPromotionalCodes,
  createPromotionalCode,
  modifyPromotionalCode,
  getAllPayPalPlans,
  createPlan,
  modifyPlan,
  deactivatePayPalPlan,
  activatePayPalPlan,
  syncPayPalWithDb,
  getProducts,
  createProduct,
}