// PropTypes imports
import PropTypes from "prop-types";

// React imports
import { useState } from "react";

// MUI imports
import { Button, FormControl, TextField, Stack } from "@mui/material";

// My components imports
import { userPropType } from "../../utils/myPropTypes";

function PromotionalCodeForm({ user, initialCode, onSubmit, intent }) {

  // State constants
  const [code, setCode] = useState(initialCode);

  // Handlers
  const handleCodeChange = (event) => {
    setCode(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      usuario_id: user.id,
      codigo: code,
    });
  }

  return (
    <Stack spacing={1}>
      <FormControl>
        <TextField value={code} onChange={handleCodeChange} />
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleSubmit}>{intent === 'create' ? 'Crear' : 'Modificar'}</Button>
    </Stack>

  );
}

PromotionalCodeForm.propTypes = {
  initialCode: PropTypes.string.isRequired,
  user: userPropType.isRequired,
  onSubmit: PropTypes.func.isRequired,
  intent: PropTypes.oneOf(['create', 'modify']).isRequired,
}

export default PromotionalCodeForm;
