// PropTypes imports
import PropTypes from 'prop-types';

// MUI imports
import { Dialog, DialogTitle, DialogContentText, DialogContent } from '@mui/material';

function MyDialog(props) {

  // Handlers
  return <>
    <Dialog open={props.open} onClose={() => props.onClose()}>
      {props.title ? <DialogTitle>{props.title}</DialogTitle> : null}
      <DialogContent>
        <DialogContentText>{props.children}</DialogContentText>
      </DialogContent>
    </Dialog>
  </>
}

MyDialog.propTypes = {
  open: PropTypes.bool.isRequired, // Value that determines whether dialog should be visible or not
  onClose: PropTypes.func.isRequired, // Function to be called when dialog is closed
  title: PropTypes.string, // Optional, string to be displayed as dialog title
}

export default MyDialog;