// React imports
import { useState, useEffect } from 'react';

// Proptypes imports
import PropTypes from 'prop-types';

// My component imports
import MyLoadingList from '../basic/myLoadingList';
import { createPlan, getAllPromotionalCodes, modifyPlan } from '../../utils/adminApi';
import { PayPalPlanForm } from '../forms/paypalForms';
import { paypalPlanPropType } from '../../utils/myPropTypes';
import { ErrorMessage } from './errorPage';



function CreateModifyPaypalPlan(props) {

  // State constants
  const [loading, setLoading] = useState(false);
  const [promotionalCodes, setPromotionalCodes] = useState([]);
  const [error, setError] = useState(false);

  // Effect hooks
  useEffect(() => {
    let isMounted = true;
    console.log('Entering CreateModifyPaypalPlan useEffect with initial values:', props.initialValues);
    if (isMounted) {
      setLoading(true);
      setError(false);
      // Get all promotional codes
      getAllPromotionalCodes()
        .then(response => {
          console.log('Setting promotional codes:', response.results);
          setPromotionalCodes(response.results);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
          setError(true);
          setLoading(false);
        });
    }
    return () => { isMounted = false };
  }, []);

  // Change handlers
  const handlePlanCreatioModification = plan => {
    // If intent is "create", then we need to create a new plan
    setLoading(true);
    if (props.initialValues.intent === 'create') {
      // Create plan
      createPlan(plan)
        .then(response => {
          console.log(response);
          // If the plan was created successfully, then we need to call the onSubmit function
          if (response) {
            props.onSubmit(response);
            setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
          setError(true);
        });
    } else {
      // Modify plan
      console.log('modifying plan with the following data:', plan);
      modifyPlan(plan)
        .then(response => {
          console.log(response);
          // If the plan was modified successfully, then we need to call the onSubmit function
          if (response) {
            props.onSubmit(response);
            setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          setLoading(false);
          setError(true);
        });
    }
  }

  return loading ?
    <MyLoadingList /> :
    (error ? <ErrorMessage /> : <PayPalPlanForm
      initialProduct={props.initialValues.products.filter(product => product.id === props.initialValues.plan.id_de_producto)[0]}
      products={props.initialValues.products}
      plan={props.initialValues.plan}
      intent={props.initialValues.intent}
      codes={promotionalCodes}
      onSubmit={handlePlanCreatioModification}
      onCancel={() => props.onCancel()}
    />)
}


CreateModifyPaypalPlan.propTypes = {
  initialValues: {
    plan: paypalPlanPropType,
    intent: PropTypes.oneOf(['create', 'modify']).isRequired,
    products: PropTypes.arrayOf(paypalPlanPropType).isRequired,
  },
  onCancel: PropTypes.func.isRequired,
  onsubmit: PropTypes.func.isRequired,
};

export default CreateModifyPaypalPlan;